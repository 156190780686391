:root {

	//COLOR
	--color_black_01: #453e37;
	--color_white_01: #f9f9f9;
	--color_white_02: #f5f4f0;
	--color_red_01  : #c74315;
	--color_red_02  : #e24631;
	// --color_gray_01: #eaeaea;
	// --color_blue_01: #2d6ead;
	// --color_blue_02: #002d74;
	// --color_green_01: #1ca182;
	--bg_color_01: var(--color_white_02);

	//FONTS
	--font_sans: 'Noto Sans JP', sans-serif;
	// --font_serif: 'Noto Serif JP', serif;
	--font_sans_en: 'Montserrat', sans-serif;
	// --font_serif_en: 'EB Garamond', seri;

	--scroll-margin-top: 80px;
}