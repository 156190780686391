@use '../mixin' as v;

.home-wrap {
	overflow: hidden;
}

//* hero--01
//---------------------------------------------------------- */
.hero-01 {
	position: relative;

	@include v.mq-up() {
		display: grid;
		grid-template-columns: v.pxtovw(300, 1440) v.pxtovw(200, 1440) v.pxtovw(120, 1440) v.pxtovw(820, 1440);
		grid-template-rows: repeat(3, auto);
		grid-column-gap: 0px;
		grid-row-gap: 0px;
	}
}

.hero-01__bg {
	@include v.mq-up() {
		top: -20%;
		left: -15%;
	}
}

.hero-01__title {
	writing-mode: vertical-rl;
	line-height: 2.2;
	letter-spacing: 0.62em;
	font-weight: 700;

	@include v.mq-down() {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@include v.fz_vw(40);
		z-index: 3;
	}

	@include v.mq-up() {
		grid-area: 1 / 1 / 2 / 3;
		justify-self: start;
		position: relative;
		padding: v.pxtovw(138, 1440) 0 v.pxtovw(90, 1440) v.pxtovw(180, 1440);
		@include v.fz_vw(32, 1440);
	}

	&.is-inview {
		@include v.mq-down() {
			transition: color 1s ease 2.4s;
			color: var(--color_white_01);
		}
	}

	.circle {
		position: relative;
		color: var(--color_white_01);
		transform: scale(1.3);
		transition-timing-function: ease-out;
		transition-duration: 1.2s;
		transition-delay: 1s;

		&::before {
			display: block;
			position: absolute;
			top: -0.25em;
			left: 0.3em;
			width: 1.56em;
			height: 1.56em;
			border-radius: 50%;
			background-color: var(--color_red_01);
			content: '';
		}

		span {
			position: relative;
		}
	}
}

.hero-01-peanuts {
	position: absolute;
	opacity: 0;
	transform: scale(0);
	transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 1.4s, opacity 0.2s ease 1.4s;
	z-index: 3;

	@include v.mq-down() {
		top: v.pxtovw(740, 750);
		left: v.pxtovw(40, 750);
		width: v.pxtovw(413, 750);
	}

	@include v.mq-up() {
		top: v.pxtovw(440, 1440);
		left: v.pxtovw(465, 1440);
		width: v.pxtovw(275, 1440);
	}

	&.is-inview {
		transform: scale(1);
		opacity: 1;
	}
}

.hero-01-peanuts__image {
	width: 100%;
	max-width: none;

	@include v.mq-up() {
		margin-bottom: v.pxtovw(50, 1440, true);
	}
}

// .hero-01-peanuts__caption {
// 	display: inline-block;
// 	position: relative;
// 	padding: 0.7em 1.7em 0.8em;
// 	background-color: #fff;
// 	text-align: center;
// 	line-height: 1.7;
// 	letter-spacing: 0.15em;
// 	font-weight: 700;

// 	@include v.mq-up() {
// 		margin-bottom: v.pxtovw(23, 1440);
// 		border-radius: v.pxtovw(30, 1440, true);
// 		font-size: 12px;
// 	}
// }

.hero-01-slider-01 {
	position: relative;
	overflow: hidden;
	z-index: 2;
	opacity: 0;

	@include v.mq-down() {
		border-radius: 0 0 0 v.pxtovw(240, 750);
		opacity: 0;
		transition: opacity 1s ease-in 1.4s;
	}

	@include v.mq-up() {
		grid-area: 1 / 4 / 3 / 6;
		height: v.pxtovw(740, 1440);
		border-radius: 0 0 0 v.pxtovw(240, 1440);
		transition: opacity 1s ease-out 0.4s;
	}

	&.is-inview {
		@include v.mq-down() {
			opacity: 1;
		}
	}

	.is-inview & {
		@include v.mq-up() {
			opacity: 1;
		}
	}

	// &::after {
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	background-color: var(--bg_color_01);
	// 	transform: scaleY(1);
	// 	transform-origin: 0 100%;
	// 	transition: transform 1s ease-out;
	// 	content: '';

	// 	.is-inview & {
	// 		transform: scaleY(0);
	// 	}
	// }
}

.hero-01-slider-01__figure {
	@include v.mq-down() {
		width: 100%;
		height: v.pxtovw(900, 750);
	}

	@include v.mq-up() {
		height: v.pxtovw(740, 1440);
	}
}

.hero-01-slider-01__picture {
	display: block;
	position: relative;
	height: 100%;
}

.hero-01-slider-01__image {
	display: block;
	position: absolute;
	width: calc(100% + 100px);
	transform-origin: 0 0;
	max-width: none;
	object-fit: cover;

	@include v.mq-down() {
		min-height: v.pxtovw(1000, 750);
		top: 0%;
	}

	@include v.mq-up() {
		bottom: -26%;
	}

	.is-visible & {
		animation: slide_animation 8s linear forwards;
	}
}


@keyframes slide_animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100px);
  }
}

.hero-01-slider-02 {
	position: relative;
	overflow: hidden;
	z-index: 2;
	opacity: 0;
	transition: opacity 1s ease-out 0.8s;

	@include v.mq-down() {
		display: none;
	}

	@include v.mq-up() {
		grid-area: 2 / 1 / 4 / 2;
		height: v.pxtovw(650, 1440);
		border-radius: 0 v.pxtovw(140, 1440) v.pxtovw(140, 1440) 0;
	}

	.is-inview & {
		opacity: 1;
	}

	// &::after {
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	background-color: var(--bg_color_01);
	// 	transform: scaleY(1);
	// 	transform-origin: 0 0;
	// 	transition: transform 1s ease-out;
	// 	content: '';

	// 	.is-inview & {
	// 		transform: scaleY(0);
	// 	}
	// }
}

.hero-01-slider-02__figure {
	@include v.mq-up() {
		height: v.pxtovw(650, 1440);
	}
}

.hero-01-slider-02__picture {
	display: block;
	position: relative;
	height: 100%;
}

.hero-01-slider-02__image {
	position: absolute;
	bottom: -16%;

	@include v.mq-up() {
		width: calc(100% + 50px);
		max-width: none;
	}

	.is-visible & {
		animation: subSlide_animation 8s linear forwards;
	}
}

@keyframes subSlide_animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50px);
  }
}

.hero__bg-text {
	position: absolute;
	bottom: v.pxtovw(52, 1440);
	color: #fff;

	@include v.mq-down() {
		display: none;
	}

	.bg-text__item {
		letter-spacing: 0.2em;
	}
}

.sticky-scroll {
	display: flex;
	align-items: center;
	position: absolute;
	writing-mode: vertical-rl;
	font-family: var(--font_sans_en);
	letter-spacing: 0.1em;
	font-weight: 700;
	-webkit-font-smoothing: antialiased;
	color: var(--color_white_01);
	transition: opacity 0.8s ease 1s;
	z-index: 3;
	opacity: 0;
	transition: opacity 0.8s ease 4s;

	@include v.mq-down() {
		display: none;
	}

	@include v.mq-up() {
		top: v.pxtovw(263, 1440);
		right: v.pxtovw(30, 1440);
		@include v.fz_vw(14, 1440);
	}

	.hero-01.is-inview & {
		opacity: 1;
	}
}


.sticky-scroll__inner {
	display: block;
	position: relative;
	width: 1px;
	margin-top: 1.5em;
	background-color: var(--color_white_01);
	content: '';

	@include v.mq-up() {
		height: v.pxtovw(90, 1440);
	}

	&::before {
		display: block;
		position: absolute;
		border-radius: 50%;
		background-color: var(--color_red_01);
		content: '';

		@include v.mq-up() {
			left: v.pxtovw(-4.5, 1440);
			width: v.pxtovw(9, 1440);
			height: v.pxtovw(9, 1440);
			animation: circlemove 2.5s ease-in-out infinite, cirlemovehide 2.5s ease-out infinite;
		}
	}
}

/*下からの距離が変化して丸の全体が上から下に動く*/
@keyframes circlemove{
	0% {
		bottom: v.pxtovw(85, 1440);
	}
	100%{
		bottom: v.pxtovw(-5, 1440);
	}
}

@keyframes circlemoveSp{
	0% {
		bottom: v.pxtovw(90, 750);
	}
	100% {
		bottom: v.pxtovw(-5, 750);
	}
}

/*上から下にかけて丸が透過→不透明→透過する*/
@keyframes cirlemovehide{
	0%{opacity: 0}
	50%{opacity: 1;}
	80%{opacity: 0.9;}
	100%{opacity: 0;}
}

//* message-section
//---------------------------------------------------------- */
.message-section {
	@include v.mq-down() {
		position: relative;
		padding: v.pxtovw(340, 750) 0 v.pxtovw(240, 750);
	}

	@include v.mq-up() {
		position: relative;
		padding-bottom: v.pxtovw(208, 1440, true);
	}
}

.message-section__bg {
	@include v.mq-down() {
		bottom: 0;
	}

	@include v.mq-up() {
		top: v.pxtovw(-410, 1440, true);
		right: v.pxtovw(-210, 1440, true);
	}
}

.message-section__inner {
	position: relative;

	@include v.mq-up() {
		display: flex;
		flex-flow: row-reverse;
		gap: v.pxtovw(100, 1440, true);
		width: v.pxtovw(1160, 1440, true);
		margin: v.pxtovw(-80, 1440, true) auto 0;
		padding-right: v.pxtovw(26, 1440, true);
		box-sizing: border-box;
	}
}

.message-section__title {
	@include v.mq-down() {
		margin: 0 auto v.pxtovw(90, 750);
	}
}

.message-section__content {
	letter-spacing: 0.6em;

	@include v.mq-down() {
		padding: 0 v.pxtovw(30, 750) 0 v.pxtovw(50, 750);
		line-height: 2.6;
	}

	@include v.mq-up() {
		line-height: 3;
		margin-top: v.pxtovw(95, 1440, true);
		font-size: 16px;
	}
}

.message__bg-text {
	position: absolute;
	color: #fff;

	@include v.mq-down() {
		bottom: v.pxtovw(-40, 750);
	}

	@include v.mq-up() {
		display: none;
	}

	.bg-text__item {
		@include v.fz_vw(140);
		letter-spacing: 0.15em;
	}
}

//* service-section
//---------------------------------------------------------- */
.service-section {
	position: relative;

	@include v.mq-down() {
		padding: v.pxtovw(225, 750) 0 0;
	}

	@include v.mq-up() {
		padding: v.pxtovw(120, 1440, true) 0 v.pxtovw(360, 1440, true);
		// @include v.fz_vw(120);
	}
}

// .service-section__bg {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	width: 100%;

// 	@include v.mq-up() {
// 		top: v.pxtovw(370, 1440, true);
// 		height: v.pxtovw(1000, 1440, true);
// 	}

// 	&::after {
// 		position: absolute;
// 		width: 100%;
// 		height: v.pxtovw(410, 1440);
// 		bottom: 0;
// 		left: 0;
// 		background: linear-gradient(0deg, var(--bg_color_01) v.pxtovw(220, 1440, true), rgba(0,0,0,0) 100%);
// 		content: '';
// 	}
// }

// .service-section__video {
// 	position: sticky;
// 	width: 100%;
// 	height: 100%;
// 	object-fit: cover;
// 	opacity: 0.6;
// }

.service-section-bg {
	@include v.mq-down() {
		top: v.pxtovw(955, 750);
		height: v.pxtovw(900, 750);
	}

	@include v.mq-up() {
		top: v.pxtovw(370, 1440, true);
		height: v.pxtovw(720, 1440, true);
	}
}

.service-section-bg__clip {
	@include v.mq-down() {
		height: v.pxtovw(800, 750);
	}

	&::before {

	}

	&::after {
		@include v.mq-down() {
			height: 20%;
		}
	}
}

.service-section__video {
	position: absolute;
	left: 0;
	width: 100%;
	background-repeat: no-repeat;
	object-fit: cover;
	opacity: 0.6;

	@include v.mq-down() {
		top: 0;
		height: v.pxtovw(850, 750);
	}

	@include v.mq-up() {
		position: fixed;
		bottom: 0;
		height: 100vh;

		@supports ( -webkit-hyphens:none ){
			position: absolute;
		}
	}
}

.service-section-peanuts {
	position: absolute;
	opacity: 0;
	transform: translateY(100%);
	transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.4s, opacity 0.2s ease 0.4s;

	@include v.mq-down() {
		top: v.pxtovw(-200, 750);
		left: calc(50% - v.pxtovw(215, 750));
		width: v.pxtovw(430, 750);
	}

	@include v.mq-up() {
		top: v.pxtovw(-118, 1440, true);
		left: v.pxtovw(75, 1440, true);
		width: v.pxtovw(253, 1440, true);
		height: v.pxtovw(164, 1440, true);
	}

	&.is-inview {
		transform: translateY(0);
		opacity: 1;
	}
}

//* speciality-section
//---------------------------------------------------------- */
.speciality-section {
	position: relative;

	@include v.mq-down() {
		position: relative;
		padding-top: v.pxtovw(590, 750);
		padding-bottom: v.pxtovw(205, 750);
		background: linear-gradient(0deg, rgba(245,244,240,1) 75%, rgba(245,244,240,1) 75%, rgba(0,0,0,0) 100%);
	}
}

.speciality-section__bg {
	@include v.mq-down() {
		bottom: 30%;
	}
}

.section-02__inner {
	position: relative;
}

//* anniversary-section
//---------------------------------------------------------- */
.anniversary-section {
	position: relative;

	@include v.mq-down() {

	}

	@include v.mq-up() {
		padding: v.pxtovw(320, 1440, true) 0 v.pxtovw(360, 1440);
	}

	// &::before {
	// 	display: block;
	// 	position: absolute;
	// 	left: 0;
	// 	content: '';

	// 	@include v.mq-up() {
	// 		top: v.pxtovw(480, 1440, true);
	// 		width: 100%;
	// 		height: v.pxtovw(780, 1440);
	// 		background: url('../images/home/anniversary_bg_pc.png') no-repeat;
	// 		background-size: cover;
	// 		background-attachment: fixed;

	// 		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
	// 			background-image: url('../images/home/anniversary_bg_pc@2x.png');
	// 		}
	// 	}
	// }

	// &::after {
	// 	position: absolute;
	// 	bottom: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	background: linear-gradient(0deg, rgba(245,244,240,1) 0%, rgba(0,0,0,0) 30%);
	// 	content: '';
	// }
}

.anniversary-section-bg {
	@include v.mq-down() {
		top: v.pxtovw(855, 750);
	}

	@include v.mq-up() {
		top: v.pxtovw(480, 1440, true);
		// height: v.pxtovw(780, 1440, true);
	}
}

.anniversary-section-bg__clip {
	&::before {
		@include v.mq-down() {
			background-image: url('../images/home/anniversary_bg_sp.png');
			background-size: cover;
		}

		@include v.mq-up() {
			background-image: url('../images/home/anniversary_bg_pc.png');

			@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
				background-image: url('../images/home/anniversary_bg_pc@2x.png');
			}
		}
	}
	&::after {
		@include v.mq-down() {
			height: 40vh;
		}
	}
}

.anniversary-section-peanuts {
	position: absolute;
	right: 0;

	@include v.mq-down() {
		top: v.pxtovw(-385, 750);
		width: v.pxtovw(373, 750);
		height: v.pxtovw(248, 750);
	}

	@include v.mq-up() {
		top: v.pxtovw(95, 1440, true);
		width: v.pxtovw(222, 1440, true);
		height: v.pxtovw(151, 1440, true);
	}
}

.anniversary-section-peanuts__image {
	width: 100%;
	height: 100%;
	opacity: 0;
	transform: translateX(100%);
	transition: transform 0.5s ease-out, opacity 0.5s ease-out;
	object-fit: cover;
	object-position: 0 0;

	.anniversary-section-peanuts.is-inview & {
		transform: translateX(0);
		opacity: 1;
	}
}

.anniversary__figure {
	// position: relative;
}

.anniversary__image {
	@include v.mq-down() {
		top: -26%;
	}
}

.anniversary__slider {
	height: 100%;
}

.anniversary__track {
	height: 100%;
}

.anniversary-parallax-text {
	position: absolute;
	overflow: hidden;

	@include v.mq-down() {
		bottom: v.pxtovw(-190, 750);
	}

	@include v.mq-up() {
		left: v.pxtovw(-140, 1440);
		bottom: v.pxtovw(340, 1440);
		z-index: 3;
	}
}

.anniversary-parallax-text__text {
	position: relative;
	white-space: nowrap;
	line-height: 1;
	letter-spacing: 0.15em;
	font-family: var(--font_sans_en);
	font-weight: 700;
	color: rgba(#f9f9f9, 0.3);

	@include v.mq-down() {
		@include v.fz_vw(140);
	}

	@include v.mq-up() {
		left: v.pxtovw(180, 1440);
		@include v.fz_vw(140, 1440);
	}
}

.anniversary-section__content {
	@include v.mq-up() {
		padding-top: v.pxtovw(148, 1440, true);
		padding-left: v.pxtovw(190, 1440, true);
		align-items: center;
	}
}

.anniversary-section__body {
	@include v.mq-up() {
		padding-top: v.pxtovw(40, 1440, true);
	}
}

.anniversary-section__button {
	@include v.mq-up() {
		margin-top: v.pxtovw(40, 1440, true);
	}
}

.anniversary__pagination {
	position: absolute;

	@include v.mq-down() {
		bottom: v.pxtovw(-120, 750);
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;
	}

	@include v.mq-up() {
		top: 45.5%;
		// transform: translateY(-50%);
		right: v.pxtovw(-80, 1440);
	}
}

//* company-section
//---------------------------------------------------------- */
.company-section {
	@include v.mq-down() {
		position: relative;
		padding-top: v.pxtovw(590, 750);
		padding-bottom: v.pxtovw(190, 750);
		background: linear-gradient(0deg, rgba(245,244,240,1) 75%, rgba(245,244,240,1) 75%, rgba(0,0,0,0) 100%);
	}

	@include v.mq-up() {
		padding-bottom: v.pxtovw(320, 1440);
	}
}

.company-section__bg {
	@include v.mq-up() {
		left: v.pxtovw(250, 1440, true);
	}
}

.company-section__inner {
	position: relative;

	@include v.mq-up() {
		width: v.pxtovw(1300, 1440);
	}
}

.company-section-peanuts {
	position: absolute;
	opacity: 0;
	transform: scale(0);
	transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s, opacity 0.4s ease 0.2s;

	@include v.mq-down() {
		width: v.pxtovw(392);
		right: v.pxtovw(30, 750);
		bottom: v.pxtovw(-60, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(220, 1440);
		height: auto;
		bottom: v.pxtovw(-14, 1440);
		left: v.pxtovw(559, 1440);
	}

	&.is-inview {
		transform: scale(1);
		opacity: 1;
	}
}

.company-section-peanuts__image {
	width: 100%;
	max-width: none;
}

.company-section__figure {
	position: relative;

	@include v.mq-up() {
		width: v.pxtovw(660, 1440);
		padding-left: v.pxtovw(60, 1440);
	}
}

.company-section__content {
	@include v.mq-up() {
		margin-top: v.pxtovw(35, 1440);
	}
}

//* recruit-section
//---------------------------------------------------------- */
.recruit-section {
	position: relative;
	color: var(--color_white_01);
	z-index: 2;

	@include v.mq-down() {
		padding: 0 v.pxtovw(30, 750);
	}

	.title-01__en {
		color: var(--color_white_01);

		&::before {
			// background-color: var(--color_white_01);
		}
	}
}

.recruit-section__figure {
	@include v.mq-down() {
		position: relative;
		height: v.pxtovw(1130, 750);
		border-radius: v.pxtovw(100, 750);
		overflow: hidden;
		z-index: 2;
	}

	@include v.mq-up() {
		height: 580px;
	}
}

.recruit-section__picture {
	display: block;
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.recruit-section__image {
	position: absolute;
	object-fit: cover;
	width: 100%;
	max-width: none;

	@include v.mq-down() {
		top: 0;
		height: v.pxtovw(1730, 750);
	}

	@include v.mq-up() {
		top: 16%;
		height: 100%;
	}
}

.recruit-section__inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	@include v.mq-down() {
		padding: v.pxtovw(138, 750) v.pxtovw(30, 750) v.pxtovw(138, 750);
		box-sizing: border-box;
		z-index: 3;
	}

	@include v.mq-up() {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: v.pxtovw(110, 1440, true);
		padding-top: v.pxtovw(20, 1440, true);
		padding-left: v.pxtovw(55, 1440, true);
		box-sizing: border-box;
	}
}

.recruit-section__content {
	@include v.mq-down() {
		padding: 0 v.pxtovw(30, 750) 0 v.pxtovw(40, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(460, 1440, true);
		padding-top: v.pxtovw(20, 1440, true);
		box-sizing: border-box;
	}
}

.recruit-section__button {
	@include v.mq-down() {
		margin-top: v.pxtovw(80, 750);
		text-align: center;
	}

	@include v.mq-up() {
		margin-top: v.pxtovw(40, 1440, true);
		padding-right: v.pxtovw(30, 1440, true);
		text-align: right;
	}
}