@use '../mixin' as v;

/* DEFAULT
---------------------------------------------------------- */
.smooth {
	scroll-behavior: smooth;
}

body {
	position: relative;
	line-height: 2.2;
	letter-spacing: 0.4em;
	font-family: var(--font_sans);
	font-weight: 700;
	color: var(--color_black_01);
	// background-image: url('../images/common/bg.svg');
	background-color: var(--bg_color_01);
	background-repeat: repeat;

	@include v.mq-down() {
		@include v.fz_vw(26);
		background-size: 15px;
	}

	@include v.mq-up() {
		background-size: 30px;
		font-size: 14px;
	}
}

:target {
	@include v.mq-down() {
		scroll-margin-top: 70px;
	}

	@include v.mq-up() {
		scroll-margin-top: 100px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
}

p {
	margin: 0;
	padding: 0;
}

figure {
	margin: 0;
}

img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
}

video {
	vertical-align: top;
}

iframe {
	@include v.mq-down() {
		width: 100%;
	}
}

svg {
	max-width: 100%;
	height: auto;
	vertical-align: top;
}

a {
	color: inherit;
	text-decoration: none;
}

a[href^='tel:'] {
	color: inherit;

	@include v.mq-up() {
		pointer-events: none;
	}
}

button {
	cursor: pointer;
}

ul,
ol,
li,
dl,
dd {
	padding: 0;
	margin: 0;
	list-style: none;
}

//* breadcrumb
//---------------------------------------------------------- */
.breadcrumb {
	display: flex;
	justify-content: flex-end;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;

	@include v.mq-down() {
		display: none;
	}

	@include v.mq-up() {
		bottom: v.pxtovw(33, 1440, true);
		width: v.pxtovw(1100, 1440);
		margin: 0 auto;
	}
}

.breadcrumb__item {
	@include v.mq-up() {
		font-size: 12px;
	}

	&:not(:last-child)::after {
		margin-left: 0.5em;
		content: '>';
		color: var(--color_white_01);
	}
}

.breadcrumb__link {
	letter-spacing: 0.15em;
	color: var(--color_white_01);
}

//* wrap
//---------------------------------------------------------- */
.wrap {
	@include v.mq-down(lgm) {
		padding-top: 60px;
	}

	@include v.mq-up(lgm) {
		padding-top: 100px;
	}
}

.main {
	// overflow: hidden;
}

//* hero-02
//---------------------------------------------------------- */
.hero-02 {
	position: relative;
	margin-left: auto;
	opacity: 0;
	transition: opacity 0.2s ease 0.2s;

	@include v.mq-down() {
		width: 100%;
	}

	@include v.mq-up() {
		width: calc(100% - 60px);
		// height: v.pxtovw(560, 1440, true);
		// margin-bottom: v.pxtovw(180, 1440, true);
	}

	&.is-inview {
		opacity: 1;
	}
}

// .hero-02__clip {
// 	position: absolute;
// 	bottom: 0;
// 	left: 0;
// 	width: 100%;
// 	clip: rect(auto, auto, auto, auto);

// 	@include v.mq-up() {
// 		height: inherit;
// 	}
// }

.hero-02__picture {
	position: relative;
	display: block;
	overflow: hidden;
	z-index: 2;

	@include v.mq-down() {
		width: 100%;
		height: v.pxtovw(580, 750);
		border-radius: 0 0 0 v.pxtovw(150, 750);
	}

	@include v.mq-up() {
		width: 100%;
		height: v.pxtovw(560, 1440);
		border-radius: 0 0 0 v.pxtovw(240, 1440);
	}
}

.hero-02__image {
	// position: fixed;
	// right: 0;
	position: absolute;
	width: 100%;
	max-width: none;
	// height: inherit;

	@include v.mq-down() {
		top: 10%;
	}

	@include v.mq-up() {
		top: 0;
		// top: 100px;
		// width: calc(100% - 60px);
	}
}

.hero-02__content {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.hero-02__title {
	color: var(--color_white_01);

	@include v.mq-down() {
		padding-left: 0.9em;
		box-sizing: border-box;
	}

	.title-01__en {
		color: var(--color_white_01);
	}
}

//* intro
//---------------------------------------------------------- */
.intro {
	overflow: hidden;
	position: relative;

	@include v.mq-down() {
		padding-bottom: v.pxtovw(293, 750);
	}

	@include v.mq-up() {
		padding-top: v.pxtovw(180, 1440, true);
		padding-bottom: v.pxtovw(172, 1440, true);
	}

	.bg-circle {
		@include v.mq-down() {
			top: v.pxtovw(600, 750);
			right: v.pxtovw(-40, 750);
			width: v.pxtovw(280, 750);
			height: v.pxtovw(280, 750);
		}
	}
}

.intro__bg {
	@include v.mq-up() {
		top: v.pxtovw(-400, 1440, true);
		right: v.pxtovw(-400, 1440, true);
	}
}

.intro-copy {
	@include v.mq-up() {
		display: flex;
		flex-flow: row-reverse;
		margin-bottom: v.pxtovw(203, 1440);
	}
}

.intro-copy__content {
	display: flex;

	@include v.mq-down() {
		justify-content: center;
		padding: v.pxtovw(120, 750) 0 v.pxtovw(155, 750);
	}

	@include v.mq-up() {
		justify-content: center;
		align-items: flex-end;
		width: v.pxtovw(720, 1440);
		padding-top: v.pxtovw(76, 1440);
		padding-right: v.pxtovw(14, 1440);
		box-sizing: border-box;
	}
}

.intro-copy__title {
	display: inline-block;
	margin: 0 auto;
	writing-mode: vertical-rl;
	text-orientation: upright;
	white-space: nowrap;
	line-height: 3;
	letter-spacing: 0.6em;
	font-weight: 700;

	@include v.mq-down() {
		@include v.fz_vw(28);
	}

	@include v.mq-up() {
		@include v.fz_vw(24, 1440);
	}
}

.intro-copy__figure {
	display: block;

	@include v.mq-down() {
		width: v.pxtovw(594, 750);
		height: v.pxtovw(450, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(720, 1440);
		height: v.pxtovw(520, 1440, true);
	}
}

.intro-copy__picture {
	display: block;
	position: relative;
	height: 100%;
	overflow: hidden;

	@include v.mq-down() {
		border-radius: 0 v.pxtovw(100, 750) v.pxtovw(100, 750) 0;
	}

	@include v.mq-up() {
		border-radius: 0 v.pxtovw(100, 1440) v.pxtovw(100, 1440) 0;
	}
}

.intro-copy__image {
	display: block;
	position: absolute;
	top: -40%;
	width: 100%;
	max-width: none;
}

.intro-lead {
	@include v.mq-down() {
		padding: v.pxtovw(40, 750) v.pxtovw(50, 750);
	}

	@include v.mq-up() {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: v.pxtovw(1180, 1440);
		margin-left: auto;
	}
}

.intro-lead__content {
	@include v.mq-up() {
		width: v.pxtovw(740, 1440);
		padding-top: v.pxtovw(30, 1440);
		box-sizing: border-box;
	}
}

.intro-lead__body {
	font-weight: 700;

	@include v.mq-up() {
		columns: 2;
		column-gap: 4em;
		padding-top: v.pxtovw(40, 1440);
		background: url('../images/common/icon_dots.svg') no-repeat 0 0;
		line-height: 2.8;
	}

	@include v.mq-down() {
		line-height: 2.6;
		@include v.fz_vw(26);
	}

	@include v.mq-up(xxl) {
		@include v.fz_vw(14, 1440);
	}
}

.intro-lead__name {
	margin-top: 2.7em;
	text-align: right;
}

.intro-lead__figure {
	display: block;
	@include v.mq-up() {
		width: v.pxtovw(200, 1440);
		height: v.pxtovw(530, 1440, true);
	}
}

.intro-lead__picture {
	display: block;
	position: relative;
	height: 100%;
	overflow: hidden;

	@include v.mq-up() {
		border-radius: v.pxtovw(100, 1440) 0 0 v.pxtovw(100, 1440);
	}
}

.intro-lead__image {
	display: block;
	position: absolute;
	top: -20%;
	width: 100%;
	max-width: none;
}

.intro__bg-text.bg-text {
	position: absolute;
	color: rgba(#fff, 0.6);

	@include v.mq-down() {
		bottom: v.pxtovw(135, 750);
	}

	@include v.mq-up() {
		top: v.pxtovw(612, 1440, true);
	}

	&--anniversary {
		color: rgba(#f7e2cb, 0.6);

		.bg-text__list {
			&:first-child {
				animation: slider1 200s -100s linear infinite;
			}

			&:last-child {
				animation: slider2 200s linear infinite;
			}
		}
	}

	.bg-text__item {
		line-height: 1;
		letter-spacing: 0.15em;
	}
}