@use '../mixin' as v;

.header {
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	background-color: rgba(#f5f4f0, 0.8);

	@include v.mq-down(lgm) {
		height: 60px;
		z-index: 11;
	}

	@include v.mq-up(lgm) {
		height: 100px;
	}
}

.header__inner {
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include v.mq-down(lgm) {
		width: 100%;
		padding-left: 15px;
		box-sizing: border-box;
	}

	@include v.mq-up(lgm) {
		width: v.pxtovw(1320, 1440, true);
		margin: 0 auto;
		padding-right: v.pxtovw(56, 1440, true);
		box-sizing: border-box;
	}
}

.header-logo {
	@include v.mq-down(lgm) {
		position: relative;
		z-index: 11;
	}
}

.header-logo__image {
	@include v.mq-down(lgm) {
		width: 180px;
	}
}

.gnav__bottom {
	@include v.mq-down(lgm) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: v.pxtovw(525, 750);
		padding: v.pxtovw(50, 750);
	}

	@include v.mq-up(lgm) {
		display: none;
	}
}

.gnav__copyright {
	line-height: 1;
	letter-spacing: 0.15em;
	font-family: var(--font_sans_en);
	color: var(--color_red_01);

	@include v.mq-down(lgm) {
		@include v.fz_vw(20);
	}
}

.gnav__button {
	line-height: 1;
}