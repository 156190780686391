@use '../mixin' as v;

.policy {
	position: relative;

	@include v.mq-down() {
		padding: v.pxtovw(120, 750) v.pxtovw(40, 750) 0 v.pxtovw(50, 750);
		overflow: hidden;
	}

	@include v.mq-up() {
		width: v.pxtovw(1040, 1440, true);
		margin: 0 auto;
		padding-top: v.pxtovw(180, 1440, true);
	}
}

.policy__bg {
	top: 0;
	right: v.pxtovw(-200, 1440, true);
}

.policy__inner {
	position: relative;

	@include v.mq-up() {
		max-width: 850px;
	}
}

.policy__desc {
	line-height: 2.4;

	@include v.mq-down() {
		margin-bottom: v.pxtovw(80, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(80, 1440, true);
	}
}