@use '../mixin' as v;

//* fade
//---------------------------------------------------------- */
.tr-fade-in {
	opacity: 0;
	transition: transform 1s 0.4s, opacity 1s 0.4s;

	&.is-inview,
	.inview-wrap.is-inview & {
		transform: none;
		opacity: 1;
	}

	@for $i from 1 through 9 {
		&.tr-fade-in--delay-0#{$i} {
			@include v.mq-up() {
				transition-delay: $i * 0.4s;
			}
		}
	}
}

.tr-fade-in--bottom {
	transform: translate(0, 30px);
}

.tr-fade-in--left {
	transform: translate(-30px, 0);
}

.tr-fade-in--scale {
	transform: scale(1.3);

	@include v.mq-up() {
		transform: scale(1.1);
	}
}

.fade--lag {
	span {
		display: inline-block;
		opacity: 0;
		transform: translateY(-0.3em);
		transition: transform 1.5s, opacity 1s;


		// @for $i from 1 through 100 {
		// 	&:nth-child(#{$i}) {
		// 		transition-delay: $i * 0.1s, $i * 0.1s;
		// 	}
		// }
	}

	.inview-wrap.is-inview & span {
		transform: translateX(0);
		opacity: 1;
	}
}

//* blur-in
//---------------------------------------------------------- */
.tr-blur-in {
	opacity: 0;
	filter: blur(1em);
	transition: filter 3s 0.6s, opacity 3s 0.6s;

	&.is-inview {
		filter: blur(0);
		opacity: 1;
	}
}

.tr-blur-in--pseudo-elements {

	&::before,
	&::after {
		opacity: 0;
		filter: blur(1em);
		transition: filter 1s 0.4s, opacity 1s 0.4s;
	}

	&.is-inview {
		&::before,
		&::after {
			filter: blur(0);
			opacity: 1;
		}
	}
}


//* slide-in
//---------------------------------------------------------- */
.slide-in__wrapper {
	display: inline-block;
  overflow: hidden;
}

.slide-in__inner {
  position: relative;
  bottom: -2em;

  .is-inview.slide-in &,
	.inview-wrap.is-inview & {
		bottom: 0;
		transition-property: bottom;
		transition-duration: 1.4s;
		transition-timing-function: ease-out;
  }

	@for $i from 1 through 9 {
		&.tr-fade-in--delay-0#{$i} {
			@include v.mq-up() {
				transition-delay: $i * 0.4s;
			}
		}
	}
}

@keyframes move_bg {
	0% {
		transform: scaleX(0);
		transform-origin: 0 100%;
	}
	49% {
		transform: scaleX(1);
		transform-origin: 0 100%;
	}
	55% {
		transform: scaleX(1);
		transform-origin: 100% 0;
	}
	100% {
		transform: scaleX(0);
		transform-origin: 100% 0;
	}
}


//* popup-text
//---------------------------------------------------------- */
.tr-popup-text {
	.s_t {
		display: inline-block;
		opacity: 0;
		transition: transform 0.4s, opacity 0.6s;
		transform: translate(0, 30px);
	}

	&.is-inview .s_t {
		transform: none;
		opacity: 1;
	}
}
