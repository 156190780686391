@use '../mixin' as v;

.service-content {
	position: relative;
}

.service-bg {
	@include v.mq-down() {
		top: v.pxtovw(260, 750);
	}

	@include v.mq-up() {
		top: v.pxtovw(260, 1440, true);
		height: 80%;
	}
}

.service-bg__clip::before {

	@include v.mq-down() {
		position: absolute;
		background-image: url('../images/service/civil_bg_sp.jpg');
	}

	@include v.mq-up() {
		background-image: url('../images/service/civil_bg_pc.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
			background-image: url('../images/service/civil_bg_pc@2x.png');
		}
	}
}

.civil {
	position: relative;
	z-index: 2;
}

//* construxtion
//---------------------------------------------------------- */
.construxtion {
	position: relative;
	z-index: 2;

	@include v.mq-down() {
		// background: linear-gradient(0deg, rgba(245,244,240,1) 80%, rgba(245,244,240,1) 80%, rgba(0,0,0,0) 100%);
	}
}