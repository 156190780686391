@use '../mixin' as v;

//* hero-03
//---------------------------------------------------------- */
.hero-03 {
	position: relative;
	opacity: 0;
	transition: opacity 0.2s ease 0.2s;

	@include v.mq-up() {
		width: calc(100% - 60px);
		margin-left: auto;
	}

	&.is-inview {
		opacity: 1;
	}
}

.hero-03__picture {
	display: block;
	position: relative;
	overflow: hidden;
	z-index: 2;

	@include v.mq-down() {
		height: v.pxtovw(580, 750);
		border-radius: 0 0 0 v.pxtovw(150, 750);
	}

	@include v.mq-up() {
		height: v.pxtovw(800, 1440);
		border-radius: 0 0 0 v.pxtovw(240, 1440);
	}
}

.hero-03__image {
	position: absolute;

	@include v.mq-down() {
		top: 20%;
		object-fit: cover;
		object-position: 40% top;
		width: 100%;
		height: v.pxtovw(700, 750);
	}

	@include v.mq-up() {
		top: 10%;
		width: 100%;
		max-width: none;
	}
}

.hero-03__content {
	display: flex;
	flex-flow: column;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	color: var(--color_white_01);
	z-index: 2;

	@include v.mq-down() {
		text-align: center;
	}

	@include v.mq-up() {
		padding: 0 v.pxtovw(80, 1440) v.pxtovw(25, 1440);
	}
}

.hero-03-number {
	display: inline-flex;
	line-height: 1;
	letter-spacing: 0.4em;

	@include v.mq-down() {
		justify-content: center;
		margin-bottom: 0.5em;
		@include v.fz_vw(20);
	}

	@include v.mq-up() {
		margin-bottom: 1.3em;
		@include v.fz_vw(14, 1440);
	}

	span {
		display: block;
		margin-top: 0.2em;
		letter-spacing: 0.06em;
		font-family: var(--font_sans_en);

		@include v.mq-down() {
			@include v.fz_vw(80);
		}

		@include v.mq-up() {
			@include v.fz_vw(74, 1440);
		}
	}
}

.hero-03-number__inner {
	text-align: center;
}

.hero-03__lead {
	line-height: 2;
	letter-spacing: 0.4em;

	@include v.mq-down() {
		margin-bottom: 0.7em;
		@include v.fz_vw(34);
	}

	@include v.mq-up() {
		margin-bottom: 0.4em;
		@include v.fz_vw(28, 1440);
	}
}

.hero-03__info {
	display: flex;
	align-items: center;

	@include v.mq-down() {
		justify-content: center;
	}
}

.hero-03__name {
	letter-spacing: 0.1em;
	margin-right: 1.2em;

	@include v.mq-down() {
		@include v.fz_vw(20);
	}

	@include v.mq-up() {
		@include v.fz_vw(16, 1440);
	}
}

.hero-03__job {
	letter-spacing: 0.4em;

	@include v.mq-down() {
		@include v.fz_vw(20);
	}

	@include v.mq-up() {
		@include v.fz_vw(14, 1440);
	}
}

//* interview
//---------------------------------------------------------- */
.interview {
	position: relative;
	overflow: hidden;

	@include v.mq-down() {
		padding: v.pxtovw(155, 750) 0 v.pxtovw(180, 750);;
	}

	@include v.mq-up() {
		padding: v.pxtovw(160, 1440, true) 0 v.pxtovw(180, 1440, true);
	}
}

.interview__bg {
	top: v.pxtovw(-300, 1440, true);
	right: v.pxtovw(-400, 1440, true);
}

.interview-03__bg {
	@include v.mq-up() {
		top: v.pxtovw(-300, 1440, true);
		right: 0;
	}
}

//* schedule
//---------------------------------------------------------- */
.schedule {
	position: relative;

	@include v.mq-down() {
		margin-bottom: v.pxtovw(80, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(60, 1440, true);
	}
}

.schedule-bg {
	height: 100%;

	@include v.mq-down() {
		height: v.pxtovw(480, 750);
	}

	@include v.mq-up() {
		top: 0;
	}
}

.schedule-bg__clip {
	height: 100%;

	&::after {
		display: none;
	}
}

.schedule__image {
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	object-fit: cover;

	@include v.mq-down() {
		position: absolute;
		object-position: 73% center;
	}

	@include v.mq-up() {
		position: fixed;
		object-position: center top;
		@supports ( -webkit-hyphens:none ){
			position: absolute;
			height: 100%;
		}
	}
}

.schedule__title {
	color: var(--color_white_01);

	@include v.mq-down() {
		height: v.pxtovw(295, 750);
		margin-bottom: v.pxtovw(0, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(55, 1440, true);
	}
}

.schedule__figure {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// .schedule__image {
// 	width: 100%;
// 	height: 100%;
// 	object-fit: cover;
// 	object-position: center top;
// }

.schedule__inner {
	position: relative;

	@include v.mq-down() {
		padding: v.pxtovw(185, 750) 0 0;
	}

	@include v.mq-up() {
		padding: v.pxtovw(132, 1440, true) 0 v.pxtovw(120, 1440, true);
	}
}

.schedule__content {
	@include v.mq-down() {
		padding-top: v.pxtovw(85, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(1160, 1440, true);
		max-width: 945px;
		margin: 0 auto;
	}
}

.schedule-item__content {
	@include v.mq-up() {
		color: var(--color_white_01);
	}
}

.schedule__list {
	display: flex;
	flex-flow: column;
	position: relative;

	@include v.mq-down() {
		gap: v.pxtovw(60, 750);
		padding: v.pxtovw(20, 750) v.pxtovw(30, 750);
	}

	@include v.mq-up() {
		gap: 40px;
		padding: v.pxtovw(20, 1440, true) 0 v.pxtovw(40, 1440, true);
	}

	&::before {
		display: block;
		position: absolute;
		top: 0;
		height: 100%;
		border-right: 1px dashed var(--color_white_01);
		content: '';

		@include v.mq-down() {
			left: v.pxtovw(130, 750);
			border-width: 2px;
			border-color: var(--color_red_02);
		}

		@include v.mq-up() {
			left: 64px;
		}
	}
}

.schedule-item {
	display: flex;
	position: relative;

	@include v.mq-down() {
		align-items: flex-start;
		gap: v.pxtovw(39, 750);
	}

	@include v.mq-up() {
		align-items: center;
		gap: 60px;
	}
}

.schedule-item__title {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-radius: 50%;
	font-weight: 700;

	@include v.mq-down() {
		margin-top: v.pxtovw(20, 750);
		min-width: v.pxtovw(200, 750);
		min-height: v.pxtovw(200, 750);
		background-color: var(--color_red_02);
		color: var(--color_white_01);
	}

	@include v.mq-up() {
		min-width: 130px;
		min-height: 130px;
		background-color: var(--color_white_01);
		color: var(--color_red_02);
	}
}

.schedule-item__time {
	display: flex;
	justify-content: center;
	align-items: center;
	letter-spacing: 0.3em;
	font-family: var(--font_sans_en);

	@include v.mq-down() {
		@include v.fz_vw(20);
	}

	@include v.mq-up() {
		font-size: 12px;
	}
}

.schedule-item__icon {
	margin-right: 0.4em;

	@include v.mq-down() {
		@include v.fz_vw(24);
	}

	@include v.mq-up() {
		font-size: 14px;
	}
}

.schedule-item__work {
	display: block;
	line-height: 1.8;
	letter-spacing: 0.2em;

	@include v.mq-down() {
		@include v.fz_vw(24);
	}

	@include v.mq-up() {
		font-size: 14px;
	}
}

.schedule-item__content {

	@include v.mq-down() {
		line-height: 2.6;
		@include v.fz_vw(24);
	}

	@include v.mq-up() {
		line-height: 2.8;
	}
}

//* other-interview
//---------------------------------------------------------- */
.other-interview__list {
	display: flex;

	@include v.mq-down() {
		justify-content: flex-start;
		padding: v.pxtovw(80, 750) v.pxtovw(30, 750);
		background-color: var(--color_white_01);
	}

	@include v.mq-up() {
		justify-content: flex-end;
		width: v.pxtovw(1200, 1440, true);
		margin: 0 auto;
	}

	// &::after {
	// 	display: block;
	// 	clear: both;
	// 	content: '';
	// }
}

.other-interview__item {

	// &--prev {
	// 	float: left;
	// }

	// &--next {
	// 	float: right;
	// }
}

.other-interview__link {
	display: flex;
	align-items: center;
	color: var(--color_red_02);

	@include v.mq-up() {
	}
}

.other-interview__figure {
	@include v.mq-down() {
		width: v.pxtovw(300, 750);
		margin-right: v.pxtovw(50, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(150, 1440, true);
		margin-right: v.pxtovw(44, 1440, true);
	}
}

.other-interview__image {
	border-radius: 50%;
}

.other-interview__content {
	padding-top: 1em;

	@include v.mq-down() {
		margin-right: v.pxtovw(35, 750);
	}

	@include v.mq-up() {
		margin-right: v.pxtovw(20, 1440, true);
	}
}

.other-interview__title {
	text-align: center;
	letter-spacing: 0.4em;
	line-height: 1;

	@include v.mq-down() {
		@include v.fz_vw(28);
	}

	@include v.mq-up() {
		@include v.fz_vw(14 , 1440, true);
	}

	span {
		display: block;
		margin-top: 0.15em;
		letter-spacing: 0.06em;
		font-family: var(--font_sans_en);

		@include v.mq-down() {
			@include v.fz_vw(148);
		}

		@include v.mq-up() {
			@include v.fz_vw(74, 1440, true);
		}
	}
}

.other-interview__info {
	display: flex;
	align-items: center;
}

.other-interview__name {
	letter-spacing: 0.1em;
	margin-right: 0.5em;

	@include v.mq-down() {
		@include v.fz_vw(32);
	}

	@include v.mq-up() {
		@include v.fz_vw(16, 1440, true);
	}
}

.other-interview__job {
	@include v.mq-down() {
		@include v.fz_vw(28);
	}

	@include v.mq-up() {
		@include v.fz_vw(14, 1440, true);
	}
}

.other-interview__icon {
	width: 1em;
	padding-top: 0.2em;
	letter-spacing: 0.4em;

	@include v.mq-down() {
		@include v.fz_vw(81);
	}

	@include v.mq-up() {
		@include v.fz_vw(50, 1440, true);
	}
}