@use '../mixin' as v;

//* gnav
//---------------------------------------------------------- */
.gnav {
	@include v.mq-down(lgm) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		padding-top: 140px;
		padding-bottom: v.pxtovw(140, 750);
		box-sizing: border-box;
		background: url('../images/common/bg_01_pc.png') no-repeat 10% 30% var(--bg_color_01);
		background-size: 130%;
		transform: translateY(-100vh);
		transition: transform 0.4s ease;
		z-index: 10;
		overflow: auto;
	}

	.is-nav-opened & {
		@include v.mq-down(lgm) {
			transform: translateY(0);
		}
	}
}

.gnav__list {
	@include v.mq-down(lgm) {
		display: grid;
		grid-template-columns: v.pxtovw(310, 750) v.pxtovw(350, 750);
		grid-template-rows: repeat(4, auto);
		grid-column-gap: 0px;
		grid-row-gap: 3.6em;
		padding: 0 v.pxtovw(40, 750) 0 v.pxtovw(50, 750);
	}

	@include v.mq-up(lgm) {
		display: flex;
		gap: v.pxtovw(30, 1440, true);
	}
}

.gnav-item {
	position: relative;

	@include v.mq-down(lgm) {
		display: block;
	}

	&:nth-child(1) {
		@include v.mq-down(lgm) {
			grid-area: 1 / 1 / 2 / 2; 
		}

		@include v.mq-up(lgm) {
			display: none;
		}
	}

	&:nth-child(2) {
		@include v.mq-down(lgm) {
			grid-area: 1 / 2 / 2 / 3; 
		}
	}

	&:nth-child(3) {
		@include v.mq-down(lgm) {
			grid-area: 2 / 1 / 3 / 2; 
		}
	}

	&:nth-child(4) {
		@include v.mq-down(lgm) {
			grid-area: 2 / 2 / 3 / 3; 
		}
	}

	&:nth-child(5) {
		@include v.mq-down(lgm) {
			grid-area: 3 / 1 / 4 / 2; 
		}
	}

	&:nth-child(6) {
		@include v.mq-down(lgm) {
			grid-area: 3 / 2 / 5 / 3; 
		}
	}

	&:nth-child(7) {
		@include v.mq-down(lgm) {
			grid-area: 4 / 1 / 5 / 2; 
		}
	}
}

.gnav__link {
	@include v.mq-down(lgm) {
		display: block;
		@include v.fz_vw(30);
	}
}

.nav-link {
	display: flex;
	align-items: center;
	letter-spacing: 0.15em;
	font-weight: 700;

	@include v.mq-down(lgm) {
		gap: 0.95em;
	}

	@include v.mq-up(lgm) {
		gap: 0.75em;
		@include v.fz_vw(12, 1440, true);
	}

	&::before {
		display: block;
		border-radius: 50%;
		background-color: var(--color_red_01);
		content: '';

		@include v.mq-down(lgm) {
			width: v.pxtovw(10, 750);
			height: v.pxtovw(10, 750);
		}

		@include v.mq-up(lgm) {
			width: 4px;
			height: 4px;
		}
	}
}

.nav-link__inner {
	display: inline-block;
	position: relative;
	line-height: 1;

	@include v.mq-up(lgm) {
		overflow: hidden;
	}

	&::before {
		@include v.mq-up(lgm) {
			position: absolute;
			bottom: 0;
			left: 0;
			transform: translateY(120%);
			transition: transform 0.6s cubic-bezier(0.25, 0.26, 0.17, 1);
			content: attr(data-text);
			speak: none;
		}


		.nav-link:hover & {
			@include v.mq-up(lgm) {
				transform: translateY(0);
			}
		}
	}
}

.nav-link__text {
	display: inline-block;
	position: relative;

	@include v.mq-up(lgm) {
		transform: translateY(0);
		transition: transform 0.6s cubic-bezier(0.25, 0.26, 0.17, 1);
	}

	.nav-link:hover & {

		@include v.mq-up(lgm) {
			transform: translateY(-120%);
		}
	}
}

.gnav-child {
	@include v.mq-down(lgm) {
		margin-top: 1.9em;
	}

	@include v.mq-up(lgm) {
		position: absolute;
		top: calc(100% + 21px);
		left: -4px;
		padding: 1.4em 1.6em 1em 1.3em;
		background-color: #fff;
		border-radius: v.pxtovw(10, 1440, true);
		white-space: nowrap;
		opacity: 0;
		transform: translateY(10px);
		transition: transform 0.6s ease 0.2s, opacity 0.6s ease 0.2s;
	}

	.gnav-item:not(:hover) & {
		@include v.mq-up(lgm) {
			animation: disable-pointer-events 0.6s forwards;
			visibility: hidden;
		}
	}

	.gnav-item:hover & {
		@include v.mq-up(lgm) {
			transform: none;
			opacity: 1;
			z-index: 2;
			visibility: visible;
		}
	}

	&::before {
		@include v.mq-up(lgm) {
			position: absolute;
			top: -10px;
			left: 30px;
			width: 12px;
			height: 10px;
			background-color: inherit;
			clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
			content: '';
		}
	}
}

@keyframes disable-pointer-events {
  0% {
    visibility: visible;
  }
  90% {
    visibility: visible;
  }
	100% {
		visibility: hidden;
	}
}

.gnav-child__item {
	line-height: 1;

	&:first-child {
		@include v.mq-down(lgm) {
			display: none;
		}
	}

	&:not(:last-child) {
		@include v.mq-down(lgm) {
			margin-bottom: 1.2em;
		}

		@include v.mq-up(lgm) {
			margin-bottom: 1em;
		}
	}
}

.gnav-child__link {
	display: flex;
	align-items: center;
	letter-spacing: 0.15em;

	@include v.mq-down(lgm) {
		@include v.fz_vw(24);
	}

	@include v.mq-up(lgm) {
		font-size: 10px;
	}

	&::before {
		display: block;
		height: 2px;
		background-color: var(--color_red_01);
		content: '';

		@include v.mq-down(lgm) {
			width: 0.7em;
			margin-right: 1.1em;
		}

		@include v.mq-up(lgm) {
			width: 0.5em;
			margin-right: 0.5em;
		}
	}
}

.gnav-child-link__inner {
	display: inline-block;
	position: relative;
	line-height: 1;

	@include v.mq-up(lgm) {
		overflow: hidden;
	}

	&::before {
		@include v.mq-up(lgm) {
			position: absolute;
			bottom: 0;
			left: 0;
			transform: translateY(120%);
			transition: transform 0.6s cubic-bezier(0.25, 0.26, 0.17, 1);
			content: attr(data-text);
			speak: none;
		}


		.gnav-child-link:hover & {
			@include v.mq-up(lgm) {
				transform: translateY(0);
			}
		}
	}
}

.gnav-child-link__text {
	display: inline-block;
	position: relative;

	@include v.mq-up(lgm) {
		transform: translateY(0);
		transition: transform 0.6s cubic-bezier(0.25, 0.26, 0.17, 1);
	}

	.gnav-child-link:hover & {

		@include v.mq-up(lgm) {
			transform: translateY(-120%);
		}
	}
}

//* Hamburger icon
//---------------------------------------------------------- */
.nav-toggle {
	display: flex;
	justify-content: center;
	align-items: center;
  z-index: 20;

	@include v.mq-down(lgm) {
		position: relative;
		width: 80px;
		height: 60px;
		background-color: var(--color_red_01);
	}

  @include v.mq-up(lgm) {
		display: none;
  }

  .is-nav-opened & {

  }
}

.nav-toggle__inner {
  display: inline-block;
	position: relative;
  align-self: center;

	@include v.mq-down(lgm) {
		width: 35px;
		height: 18px;
	}

	@include v.mq-up(lgm) {
		width: 40px;
		height: 26px;
	}
}

.nav-toggle__inner span {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color_white_01);
  transition: all .4s;
  box-sizing: border-box;

	.is-nav-opened & {
    // background-color: v.$base-color01
  }
}

.nav-toggle__bar--01 {
  top: 0;

	.is-nav-opened & {
		transform: translate(50%) scale(0);
	}

	.nav-toggle:hover & {
		@include v.mq-up(lgm) {
			top: 50%;
			opacity: 0;
		}
	}
}

.nav-toggle__bar--02 {
  top: 50%;
  transform: translateY(-50%);

	&:after {
		position: absolute;
		top: 0;
		left: 0;
		content: '';
		width: 100%;
		height: 2px;
		background-color: var(--color_white_01);
		transition: all 0.4s;
		opacity: 0;

		.is-nav-opened & {
			opacity: 1;
			background-color: var(--color_white_01);
		}
	}

	.is-nav-opened & {
		transform: rotate(30deg);

		&:after {
			transform: rotate(120deg);
		}
	}
}

.nav-toggle__bar--03 {
  bottom: 0;

	.is-nav-opened & {
		transform: translate(-50%) scale(0);
	}

	.nav-toggle:hover & {
		@include v.mq-up(lgm) {
			bottom: 50%;
			opacity: 0;
		}
	}
}

.gnav-peanuts {
	position: absolute;
	opacity: 0;
	transform: scale(0);
	transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s ease;
	z-index: 2;

	@include v.mq-down(lgm) {
		top: v.pxtovw(783, 750);
		right: v.pxtovw(30, 750);
		width: v.pxtovw(413, 750);
		height: v.pxtovw(419, 750);
	}

	@include v.mq-up(lgm) {
		display: none;
	}

	.is-nav-opened & {
		transition-delay: 0.8s;
		transform: scale(1);
		opacity: 1;
	}
}

.gnav__bg-text {
	position: absolute;
	top: v.pxtovw(790, 750);
	z-index: 1;
	color: var(--color_white_01);

	.bg-text__item {
		@include v.mq-down(lgm) {
			@include v.fz_vw(200);
		}
	}
}