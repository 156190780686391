@use '../mixin' as v;

//* work
//---------------------------------------------------------- */
.work {

}

.work__outer {
	position: relative;

	// @include v.mq-up() {
	// 	background: url('../images/recruit/work_bg_pc.png') no-repeat;
	// 	background-size: 100%;

	// 	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
	// 		background-image: url('../images/recruit/work_bg_pc@2x.png');
	// 	}
	// }
}

.work-bg {
	top: 0;

	@include v.mq-up() {
		height: 80%;
	}
}

.work-bg__clip::before {

	@include v.mq-down() {
		background-image: url('../images/recruit/work_bg_sp.jpg');
	}

	@include v.mq-up() {
		background-image: url('../images/recruit/work_bg_pc.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
			background-image: url('../images/recruit/work_bg_pc@2x.png');
		}
	}
}

//* education
//---------------------------------------------------------- */
.education {
	@include v.mq-down() {
		padding-bottom: v.pxtovw(125, 750);
	}

	@include v.mq-up() {
		padding-bottom: v.pxtovw(170, 1440, true);
	}
}

.education__title {
	@include v.mq-down() {
		margin-bottom: v.pxtovw(65, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(75, 1440, true);
	}
}

//* environment
//---------------------------------------------------------- */
.environment {
	position: relative;

	@include v.mq-down() {
		padding-bottom: v.pxtovw(145, 750);
		overflow: hidden;
	}

	@include v.mq-up() {
		padding-bottom: v.pxtovw(175, 1440, true);
	}
}

.environment__bg {
	@include v.mq-up() {
		bottom: 0;
	}
}

.environment__title {
	@include v.mq-down() {
		margin-bottom: v.pxtovw(155, 750);
	}
	@include v.mq-up() {
		margin-bottom: v.pxtovw(75, 1440, true);
	}
}

.environment__list {
	display: flex;
	flex-flow: column;
	position: relative;

	@include v.mq-down() {
		padding: 0 v.pxtovw(30, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(1160, 1440, true);
		max-width: 1060px;
		margin: 0 auto;
		gap: v.pxtovw(40, 1440, true);
	}
}

.environment-item {
	background-color: var(--color_white_01);

	@include v.mq-down() {
		padding: 0 v.pxtovw(30, 750) v.pxtovw(80, 750) v.pxtovw(40, 750);
		border-radius: v.pxtovw(40, 750);
	}

	@include v.mq-up() {
		display: flex;
		align-items: center;
		gap: v.pxtovw(60, 1440, true);
		min-height: 240px;
		padding: 30px v.pxtovw(60, 1440, true);
		border-radius: v.pxtovw(42, 1440, true);
		box-sizing: border-box;
	}

	&:not(:last-child) {
		@include v.mq-down() {
			margin-bottom: v.pxtovw(180, 750);
		}
	}
}

.environment-item__figure {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: var(--color_red_02);

	@include v.mq-down() {
		width: v.pxtovw(180, 750);
		height: v.pxtovw(180, 750);
		margin: v.pxtovw(-90, 750) auto 0.7em;
	}

	@include v.mq-up() {
		min-width: 120px;
		min-height: 120px;
	}
}

.environment-item__image {
	@include v.mq-down() {
		width: v.pxtovw(70, 750);
	}

	@include v.mq-up() {
		width: 50px;
	}
}

.environment-item__content {
	@include v.mq-up() {
		// width: v.pxtovw(755, 1440, true);
	}
}

.environment-item__title {
	letter-spacing: 0.6em;
	font-weight: 700;
	color: var(--color_red_02);

	@include v.mq-down() {
		margin-bottom: 0.5em;
		text-align: center;
		@include v.fz_vw(30);
	}

	@include v.mq-up() {
		margin-bottom: 0.3em;
		font-size: 18px;
	}
}

.environment-item_body {
	@include v.mq-down() {
		line-height: 2.6;
	}

	@include v.mq-up() {
		line-height: 2.8;
	}
}

//* interview-section
//---------------------------------------------------------- */
.interview-section {
	@include v.mq-up() {

	}
}

.interview-section__title {
	@include v.mq-down() {
		margin-bottom: v.pxtovw(72, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(75, 1440, true);
	}
}

.interview-section__inner {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	clip: rect(auto, auto, auto, auto);
}

.interview-section-item__mouse {
	position: fixed;
	pointer-events: none;
	z-index: 2;

	@include v.mq-up() {
		width: 130px;
		height: 130px;
		opacity: 0;
		transition: opacity 0.4s ease;
	}

	&.is-view {
		opacity: 1;
	}
}

.mouse__bg-01{
	fill-rule: evenodd;
	clip-rule: evenodd;
	fill: #D64631;
	transition: fill 0.4s ease;

	.is-mouseenter-detail-1 & {
		fill: #46a3d3;
	}
}

.mouse__bg-02{
	fill-rule: evenodd;
	clip-rule: evenodd;
	fill: #FAFAFA;
}

.mouse__text {
	fill-rule: evenodd;
	clip-rule: evenodd;
	fill: #F4F4EF;
	animation: 60s linear infinite rotation;
	transform-origin: center;
}

@keyframes rotation {
  0%{ transform: rotate(0);}
  100%{ transform: rotate(360deg); }
}

.interview-section__list {
	display: flex;

	@include v.mq-down() {
		flex-flow: column;
		gap: v.pxtovw(4, 750);
	}

	@include v.mq-up() {
		gap: 1px;
	}
}

.interview-section-item {
	position: relative;

	@include v.mq-up() {
		width: 50%;
	}
}

.interview-section-item__icon {
	@include v.mq-down() {
		position: absolute;
		top: v.pxtovw(30, 750);
		right: v.pxtovw(22, 750);
		width: v.pxtovw(220, 750);
		height: v.pxtovw(220, 750);
	}

	@include v.mq-up() {
		display: none;
	}

	&--01 {
		.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#D64631;}
		.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FAFAFA;}
		.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#F4F4EF;}

		@include v.mq-up() {
			display: none;
		}
	}

	&--02 {
		.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#469AC6;}
		.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FAFAFA;}
		.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#F4F4EF;}
		.st3{fill-rule:evenodd;clip-rule:evenodd;fill:#D64631;}

	}
}

.interview-section-item__image {
	@include v.mq-up() {
		width: 100%;
		max-width: none;
	}
}

.interview-section-item__content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: var(--color_white_01);
	box-sizing: border-box;

	@include v.mq-down() {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: v.pxtovw(140, 750);
	}

	@include v.mq-up() {
		display: flex;
		align-items: flex-end;
		padding: v.pxtovw(40, 1440) v.pxtovw(60, 1440);
	}
}

.interview-section-item__number {
	@include v.mq-up() {
		grid-area: 1 / 1 / 3 / 2;
	}
}

.interview-section-item__lead {
	@include v.mq-up() {
		grid-area: 1 / 2 / 2 / 3;
	}
}

.interview-section-item__info {
	@include v.mq-up() {
		grid-area: 2 / 2 / 3 / 3;
	}
}

.interview-section-item-number {
	line-height: 1;
	letter-spacing: 0.4em;

	@include v.mq-down() {
		display: none;
	}

	@include v.mq-up() {
		width: v.pxtovw(130, 1440);
		padding-bottom: 0.5em;
		box-sizing: border-box;
		@include v.fz_vw(14, 1440);
	}
}

.interview-section-item-number__inner {
	display: inline-flex;
	flex-flow: column;
	text-align: center;

	span {
		display: block;
		line-height: 1;
		letter-spacing: 0.06em;
		font-family: var(--font_sans_en);

		@include v.mq-up() {
			margin-top: 0.15em;
			@include v.fz_vw(74, 1440);
		}
	}
}

.interview-section-item__body {
	@include v.mq-down() {
		text-align: center;
	}

	@include v.mq-up() {
		padding-left: v.pxtovw(40, 1440);
		border-left: v.pxtovw(4, 1440) solid;
	}
}

.interview-section-item__lead {
	line-height: 2;
	letter-spacing: 0.4em;
	font-weight: 700;

	@include v.mq-down() {
		margin-bottom: 0.3em;
		@include v.fz_vw(34);
	}

	@include v.mq-up() {
		margin-bottom: 0.1em;
		@include v.fz_vw(20, 1440);
	}
}

.interview-section-item__info {
	@include v.mq-up() {
		@include v.fz_vw(16, 1440);
	}
}

.interview-section-item__name {
	letter-spacing: 0.1em;
	font-family: var(--font_sans_en);

	@include v.mq-down() {
		@include v.fz_vw(20);
	}

	@include v.mq-up() {
		@include v.fz_vw(16, 1440);
	}
}

.interview-section-item__job {
	margin-left: 1.3em;
	letter-spacing: 0.4em;

	@include v.mq-down() {
		@include v.fz_vw(20);
	}

	@include v.mq-up() {
		@include v.fz_vw(14, 1440);
	}
}