@use 'sass:math';

//* Media Queries
//---------------------------------------------------------- */
// min-width
$breakpoint-up: (
	'sm': 'and (min-width: 400px)',
	'md': 'and (min-width: 768px)',
	'ml': 'and (min-width: 801px)',
	'lg': 'and (min-width: 1000px)',
	'lgm': 'and (min-width: 1025px)',
	'lgp': 'and (min-width: 1200px)',
	'xl': 'and (min-width: 1250px)',
	'xml': 'and (min-width: 1290px)',
	'xxl': 'and (min-width: 1441px)',
) !default;

// max-width
$breakpoint-down: (
	'sm': 'and (max-width: 399px)',
	'md': 'and (max-width: 767px)',
	'ml': 'and (max-width: 800px)',
	'lg': 'and (max-width: 999px)',
	'lgm': 'and (max-width: 1024px)',
	'lgp': 'and (max-width: 1199px)',
	'xl': 'and (max-width: 1249px)',
	'xml': 'and (min-width: 1289px)',
	'xxl': 'and (max-width: 1440px)',
) !default;

@mixin mq-up($breakpoint1: md, $breakpoint2: null) {
	@if $breakpoint2 {
		@media #{'screen'} #{map-get($breakpoint-up, $breakpoint1)}#{map-get($breakpoint-down, $breakpoint2)} {
			@content;
		}
	} @else {
		@media #{'screen'} #{map-get($breakpoint-up, $breakpoint1)} {
			@content;
		}
	}
}

@mixin mq-down($breakpoint1: md, $breakpoint2: null) {
	@if $breakpoint2 {
		@media #{'screen'} #{map-get($breakpoint-down, $breakpoint1)}#{map-get($breakpoint-up, $breakpoint2)} {
			@content;
		}
	} @else {
		@media #{'screen'} #{map-get($breakpoint-down, $breakpoint1)} {
			@content;
		}
	}
}

//* widtnPercent
//---------------------------------------------------------- */
@function widtnPercent($pxWidth, $parentPxWidth) {
	@return math.div(floor(((math.div($pxWidth, $parentPxWidth)) * 10000%)), 100);
}

//* pxtovw
//---------------------------------------------------------- */
@function pxtovw($size: 100, $viewport: 750, $max: false) {
	$result: (1vw * math.div($size, $viewport)  * 100);
	@if $max == true {
		@if $size < 0 {
			// @return #{$size}px;
			@return max(#{$result}, #{$size}px);
		} @else {
			@return min(#{$result}, #{$size}px);
		} 
	} @else {
		@return $result;
	}
}

//* pxtovh
//---------------------------------------------------------- */
@function pxtovh($size: 100, $viewport: 750, $max: false) {
	$result: (1vh * math.div($size, $viewport)  * 100);
	@if $max == true {
		@return min(#{$result}, #{$size}px);
	} @else {
		@return $result;
	}
}

//* fz_vw
//---------------------------------------------------------- */
@function get_vw($size, $viewport) {
	$rate: math.div(100, $viewport);
	@return $rate * $size * 1vw;
}

@mixin fz_vw($font_size: 10, $viewport: 750, $max: false) {
	@if $max == true {
		font-size: min(#{get_vw($font_size, $viewport)}, #{$font_size}px);
	} @else {
		font-size: get_vw($font_size, $viewport);
	}
}

//* fz_vh
//---------------------------------------------------------- */
@function get_vh($size, $viewport) {
	$rate: math.div(100, $viewport);
	@return $rate * $size * 1vh;
}

@mixin fz_vh($font_size: 10, $viewport: 750, $max: false) {
	@if $max == true {
		font-size: min(#{get_vh($font_size, $viewport)}, #{$font_size}px);
	} @else {
		font-size: get_vh($font_size, $viewport);
	}
}