@use '../mixin' as v;
@use 'sass:math';

//* fade-alpha
//---------------------------------------------------------- */
.fade-alpha {
	@include v.mq-up() {
		transition: opacity 0.4s ease-in;
	}

	&:hover {
		@include v.mq-up() {
			transition-timing-function: ease-out;
			opacity: 0.7;
			text-decoration: none;
		}
	}
}

//* alpha-bg
//---------------------------------------------------------- */
.alpha-bg {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;

	&::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background-color: var(--bg_color_01);
		content: '';
		z-index: 1;
	}
}

.alpha-bg__clip {
	position: absolute;
	width: 100%;
	clip: rect(auto, auto, auto, auto);

	@include v.mq-down() {
		height: calc(var(--vh, 1vh) * 100);
	}

	@include v.mq-up() {
		height: 100vh;

		@supports ( -webkit-hyphens:none ){
			height: 100%;
		}
	}

	&::before {
		bottom: 0;
		left: 0;
		width: 100%;
		background-repeat: no-repeat;
		content: '';

		@include v.mq-down() {
			position: absolute;
			background-size: calc(var(--vh, 1vh) * 100);
		}

		@include v.mq-up() {
			position: fixed;
			height: 100%;
			background-size: 100%;

			@supports ( -webkit-hyphens:none ){
				position: absolute;
				background-attachment: fixed;
				background-size: 100%;
			}
		}

		@include v.mq-down() {
			height: 100vh;
		}
	}

	&::after {
		position: absolute;
		bottom: 0;
		width: 100%;
		content: '';
		background: linear-gradient(0deg, rgba(245,244,240,1) 0%, rgba(0,0,0,0) 100%);

		@include v.mq-down() {
			height: v.pxtovw(750, 500);
		}

		@include v.mq-up() {
			height: 500px;
		}
	}
}

//* title-01
//---------------------------------------------------------- */
.title-01 {
	writing-mode: vertical-rl;
}

.title-01__en {
	display: flex;
	align-items: center;
	margin-top: 0.25em;
	line-height: 2;
	font-family: var(--font_sans_en);
	font-weight: 700;
	color: var(--color_red_01);
	transition: opacity 0.6s ease;
	opacity: 0;

	@include v.mq-down() {
		margin-left: 0.1em;
		letter-spacing: 0.2em;
		// @include v.fz_vw(24);
		@include v.fz_vw(20);
	}

	@include v.mq-up() {
		margin-left: 0.3em;
		letter-spacing: 0.3em;
		@include v.fz_vw(14, 1440, true);
	}

	.is-inview & {
		opacity: 1;
	}

	.title-01--small & {
		@include v.mq-up() {
			margin-top: 0;
			margin-left: 1em;
			letter-spacing: 0.2em;
		}
	}

	&::before {
		display: block;
		margin-bottom: 0.7em;
		border-radius: 50%;
		background-color: var(--color_red_01);
		content: '';

		@include v.mq-down() {
			// width: v.pxtovw(20, 750);
			// height: v.pxtovw(20, 750);
			width: v.pxtovw(16, 750);
			height: v.pxtovw(16, 750);
		}

		@include v.mq-up() {
			width: v.pxtovw(8, 1440, true);
			height: v.pxtovw(8, 1440, true);
		}
	}
}

.title-01__ja {
	display: block;
	text-orientation: upright;
	letter-spacing: 0.62em;
	font-weight: 700;

	@include v.mq-down() {
		white-space: nowrap;
		line-height: 2.4;
		@include v.fz_vw(36);
	}

	@include v.mq-up() {
		line-height: 2;
		@include v.fz_vw(30, 1440, true);
	}

	.title-01--small & {
		@include v.mq-up() {
			line-height: 2.4;
			@include v.fz_vw(24, 1440, true);
		}
	}
}

//* title-02
//---------------------------------------------------------- */
.title-02 {
	text-align: center;
}

.title-02__ja {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 0.4em;
	line-height: 1.6;
	letter-spacing: 0.4em;
	font-weight: 700;

	@include v.mq-down() {
		margin-bottom: 0.65em;
		@include v.fz_vw(30);
	}

	@include v.mq-up() {
		margin-bottom: 0.36em;
		font-size: 26px;
	}
}

.title-02__en {
	display: block;
	line-height: 1.6;
	letter-spacing: 0.3em;
	font-family: var(--font_sans_en);
	font-weight: 700;

	@include v.mq-down() {
		@include v.fz_vw(24);
	}

	@include v.mq-up() {
		font-size: 14px;
	}

	&--red {
		color: var(--color_red_02);
	}
}

.title-02__icon {
	@include v.mq-down() {
		margin-right: 0.3em;
	}

	@include v.mq-up() {
		margin-right: 0.1em;
	}

	&--civil {
		@include v.mq-up() {
			font-size: 36px;
		}
	}

	&--construxtion {
		@include v.mq-up() {
			font-size: 32px;
		}
	}

	&--advantage {
		@include v.mq-up() {
			font-size: 29px;
		}
	}

	&--certificate {
		@include v.mq-up() {
			font-size: 28px;
		}
	}

	&--works {
		@include v.mq-up() {
			font-size: 40px;
		}
	}

	&--message {
		@include v.mq-up() {
			font-size: 29px;
		}
	}

	&--history {
		@include v.mq-up() {
			font-size: 27px;
		}
	}

	&--philosophy {
		@include v.mq-up() {
			font-size: 29px;
		}
	}

	&--info {
		@include v.mq-up() {
			font-size: 30px;
		}
	}

	&--work {
		@include v.mq-down() {
			@include v.fz_vw(54);
		}

		@include v.mq-up() {
			font-size: 50px;
		}
	}

	&--education {
		@include v.mq-up() {
			font-size: 31px;
		}
	}

	&--environment {
		@include v.mq-down() {
			@include v.fz_vw(45);
		}

		@include v.mq-up() {
			font-size: 43px;
		}
	}

	&--interview {
		@include v.mq-down() {
			@include v.fz_vw(33);
		}

		@include v.mq-up() {
			font-size: 28px;
		}
	}

	&--clock {
		@include v.mq-up() {
			font-size: 27px;
		}
	}
}

//* title-03
//---------------------------------------------------------- */
.title-03 {

}

.title-03__en {
	display: flex;
	align-items: center;
	line-height: 1.6;
	letter-spacing: 0.3em;
	font-family: var(--font_sans_en);
	font-weight: 700;
	color: var(--color_red_02);

	@include v.mq-down() {
		margin-bottom: 0.4em;
		@include v.fz_vw(20);
	}

	@include v.mq-up() {
		margin-bottom: 0.5em;
		font-size: 12px;
	}

	&::before {
		order: 2;
		height: 1px;
		background-color: var(--color_red_02);
		content: '';

		@include v.mq-down() {
			width: 3em;
			margin: 0 1em 0 0.8em;
		}

		@include v.mq-up() {
			width: 2.5em;
			margin: 0 1em 0 0.5em;
		}
	}

	&::after {
		order: 3;
		content: counter(title-03-counter, decimal-leading-zero);
	}
}

.title-03__ja {
	display: block;
	line-height: 2.6;
	letter-spacing: 0.6em;
	font-weight: 700;

	@include v.mq-down() {
		font-feature-settings: "palt";
		@include v.fz_vw(30);
	}

	@include v.mq-up() {
		font-size: 20px;
	}
}

//* link-01
//---------------------------------------------------------- */
.link-01 {
	&:hover {
		@include v.mq-up() {
			text-decoration: underline;
		}
	}
}

//* button-01
//---------------------------------------------------------- */
.button-01 {
	display: inline-block;
	padding: 1.6em 2.2em 1.6em 3.2em;
	border-radius: 2em;
	background-color: var(--color_red_01);
	line-height: 1;
	letter-spacing: 0.3em;
	color: var(--color_white_01);

	@include v.mq-down() {
		@include v.fz_vw(24);
	}

	@include v.mq-up() {
		font-size: 12px;
	}
}

.button-01__inner {
	display: flex;
	align-items: center;
	position: relative;

	@include v.mq-up() {
		overflow: hidden;
	}

	&::after {
		display: block;
		margin-left: 1em;
		border-radius: 50%;
		background-color: var(--color_white_01);
		content: '';

		@include v.mq-down() {
			width: v.pxtovw(12, 750);
			height: v.pxtovw(12, 750);
		}

		@include v.mq-up() {
			width: 6px;
			height: 6px;
		}

		.button-01:hover & {
			@include v.mq-up() {
				animation: arrow 0.5s;
			}
		}
	}

	&::before {
		@include v.mq-up() {
			position: absolute;
			bottom: 0;
			left: 0;
			transform: translateY(120%);
			transition: transform 0.6s cubic-bezier(0.25, 0.26, 0.17, 1);
			content: attr(data-text);
			speak: none;
		}


		.button-01:hover & {
			@include v.mq-up() {
				transform: translateY(0);
			}
		}
	}
}

.button-01__text {
	display: inline-block;

	@include v.mq-up() {
		transform: translateY(0);
		transition: transform 0.6s cubic-bezier(0.25, 0.26, 0.17, 1);
	}

	.button-01:hover & {

		@include v.mq-up() {
			transform: translateY(-120%);
		}
	}
}

@keyframes arrow {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  49.9% {
    transform: translateX(150%);
    opacity: 0;
  }
  50% {
    transform: translateX(-150%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

//* button-02
//---------------------------------------------------------- */
.button-02 {
	display: inline-block;
	padding: 1.43em 3.5em;
	border-radius: 3em;
	background-color: var(--color_red_02);
	letter-spacing: 0.3em;
	color: var(--color_white_01);

	@include v.mq-down() {
		@include v.fz_vw(24);
	}

	@include v.mq-up() {
		font-size: 18px;
	}
}

.button-02__inner {
	display: inline-block;
	position: relative;
	line-height: 1;

	@include v.mq-up() {
		overflow: hidden;
	}

	&::before {
		@include v.mq-up() {
			position: absolute;
			bottom: 0;
			left: 0;
			transform: translateY(120%);
			transition: transform 0.6s cubic-bezier(0.25, 0.26, 0.17, 1);
			content: attr(data-text);
			speak: none;
		}


		.button-02:hover & {
			@include v.mq-up() {
				transform: translateY(0);
			}
		}
	}
}

.button-02__text {
	display: inline-block;

	@include v.mq-up() {
		transform: translateY(0);
		transition: transform 0.6s cubic-bezier(0.25, 0.26, 0.17, 1);
	}

	.button-02:hover & {

		@include v.mq-up() {
			transform: translateY(-120%);
		}
	}
}

.button-02__icon {
	margin-left: 0.5em;
}

//* button-03
//---------------------------------------------------------- */
.button-03 {
	display: inline-block;
	border-radius: 3em;
	padding: 0.4em 0.77em;
	background-color: var(--color_red_02);
	line-height: 1;
	letter-spacing: 0.15em;
	color: var(--color_white_01);

	@include v.mq-down() {
		@include v.fz_vw(20);
	}

	@include v.mq-up() {
		font-size: 10px;
	}
}

//* button-04
//---------------------------------------------------------- */
.button-04 {
	display: inline-block;
	border-radius: 3em;
	padding: 0.59em 0.8em 0.75em 1.5em;
	background-color: var(--color_red_02);
	line-height: 1;
	letter-spacing: 0.3em;
	font-family: var(--font_sans_en);
	color: var(--color_white_01);

	@include v.mq-up() {
		font-size: 12px;
	}
}

.button-04__icon {
	margin-left: 0.5em;
}

//* dl-01
//---------------------------------------------------------- */
.dl-01 {
	@include v.mq-down() {
		padding: 0 v.pxtovw(50, 750) 0;
	}

	@include v.mq-up() {
		columns: 2;
		column-gap: v.pxtovw(100, 1440, true);
		width: v.pxtovw(1160, 1440, true);
		max-width: 900px;
		margin: 0 auto;
	}

	dt, dd {
		@include v.mq-down() {
			padding-right: 1em;
			padding-left: 1em;
		}

		@include v.mq-up() {
			padding: 0 0 1.2em v.pxtovw(20, 1440, true);
		}
	}

	dt {
		margin-bottom: 0.2em;
		line-height: 1;
		letter-spacing: 0.2em;
		font-weight: 700;
		color: var(--color_red_02);

		@include v.mq-down() {
			margin-bottom: 1em;
			@include v.fz_vw(20);
		}

		@include v.mq-up() {
			font-size: 10px;
		}
	}

	dd {
		border-bottom: 1px dashed;
		line-height: 1.6;

		@include v.mq-down() {
			padding-bottom: 1.2em;
		}

		&:not(:last-child) {
			margin-bottom: 1.5em;
		}
	}
}

//* dl-02
//---------------------------------------------------------- */
.dl-02 {
	dt {
		margin-bottom: 0.9em;
		letter-spacing: 0.6em;
		@include v.mq-down() {
			@include v.fz_vw(30);
		}

		@include v.mq-up() {
			font-size: 20px;
		}
	}

	dd {
		line-height: 2.4;
		letter-spacing: 0.4em;

		&:not(:last-child) {
			margin-bottom: 4.1em;
		}
	}
}

//* section-01
//---------------------------------------------------------- */
.section-01 {

}

.section-01__inner {
	position: relative;
	z-index: 2;

	@include v.mq-down() {
		width: v.pxtovw(690, 750);
		margin: 0 auto;
	}

	@include v.mq-up() {
		width: v.pxtovw(1160, 1440);
		margin: 0 auto;
	}
}

.section-01__figure {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 2;

	@include v.mq-down() {
		border-radius: v.pxtovw(100, 750);
	}

	@include v.mq-up() {
		border-radius: v.pxtovw(100, 1440);
	}
}

.section-01__picture {
	display: block;
	height: v.pxtovw(580, 1440);
}

.section-01__image {
	position: absolute;
	bottom: 16%;

	@include v.mq-up() {
		width: 100%;
		height: 101%;
		object-fit: cover;
	}
}

.section-01__content {
	position: relative;
	box-sizing: border-box;
	color: var(--color_white_01);
	z-index: 3;

	@include v.mq-down() {
		height: v.pxtovw(1130, 750);
		padding: v.pxtovw(140, 750) 0 v.pxtovw(63, 750);
	}

	@include v.mq-up() {
		display: flex;
		justify-content: space-between;
		min-height: v.pxtovw(580, 1440);
		padding: v.pxtovw(165, 1440) v.pxtovw(140, 1440) v.pxtovw(129, 1440) v.pxtovw(170, 1440);
		// gap: v.pxtovw(120, 1440);
	}
}

.section-01__title {
	display: flex;

	@include v.mq-down() {
		justify-content: center;
		margin-bottom: v.pxtovw(55, 750);
		padding-left: 1.5em;
	}

	@include v.mq-up() {
		justify-content: flex-end;
		width: v.pxtovw(200, 1440);
		padding-top: 0.8em;
	}

	.title-01__en {
		color: var(--color_white_01);

		&::before {
			// background-color: var(--color_white_01);
		}
	}
}

.section-01__body {
	@include v.mq-down() {
		padding: 0 v.pxtovw(20, 750) 0 v.pxtovw(40, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(540, 1440);
		padding-right: v.pxtovw(80, 1440);
		box-sizing: border-box;
	}
}

.section-01__text {
	@include v.mq-down() {
		line-height: 2.6;
	}

	@include v.mq-up() {
		line-height: 2.8;
	}
}

.section-01__button {
	@include v.mq-down() {
		margin-top: v.pxtovw(80, 750);
		text-align: center;
	}

	@include v.mq-up() {
		margin-top: v.pxtovw(40, 1440);
		text-align: right;
	}
}

//* section-02
//---------------------------------------------------------- */
.section-02__inner {
	display: flex;

	@include v.mq-down() {
		flex-flow: column;
	}

	@include v.mq-up() {
		justify-content: space-between;
		align-items: center;
		width: v.pxtovw(1300, 1440);
		margin-right: auto;
	}
}

.section-02__figure {
	display: block;

	@include v.mq-down() {
		order: 2;
		width: 100%;
		height: v.pxtovw(600, 750);
	}


	@include v.mq-up() {
		width: v.pxtovw(720, 1440);
		height: v.pxtovw(600, 1440);
	}
}

.section-02__picture {
	display: block;
	position: relative;
	height: 100%;
	overflow: hidden;

	@include v.mq-up() {
		border-radius: 0 v.pxtovw(100, 1440) v.pxtovw(100, 1440) 0;
	}
}


.section-02__image {
	position: absolute;
	bottom: 10%;

	@include v.mq-up() {
		width: 100%;
		max-width: none;
	}
}

.section-02__content {
	@include v.mq-down() {
		order: 1;
		margin-bottom: v.pxtovw(245, 750);
		padding: 0 v.pxtovw(30, 750) 0 v.pxtovw(50, 750);
	}

	@include v.mq-up() {
		display: flex;
		flex-flow: column;
		align-items: center;
		width: v.pxtovw(430, 1440);
	}
}

.section-02__title {
	@include v.mq-down() {
		margin: 0 auto 1.3em;
		padding-left: 1em;
	}

	@include v.mq-up() {
		margin-bottom: 1.8em;
	}
}

.section-02__body {
	@include v.mq-down() {
		line-height: 2.6;
	}

	@include v.mq-up() {
		line-height: 2.8;
	}
}

.section-02__button {
	@include v.mq-down() {
		margin-top: v.pxtovw(82, 750);
		text-align: center;
	}

	@include v.mq-up() {
		width: 100%;
		margin-top: v.pxtovw(40, 1440, true);
		text-align: right;
	}
}

//* section-03
//---------------------------------------------------------- */
.section-03 {

	&:not(:last-child) {
		@include v.mq-down() {
			padding-bottom: v.pxtovw(95, 750);
		}

		@include v.mq-up() {
			padding-bottom: v.pxtovw(130, 1440, true);
		}
	}
}

.section-03__inner {
	@include v.mq-down() {
		padding: 0 v.pxtovw(30, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(1160, 1440, true);
		margin: 0 auto;
	}
}

.section-03__figure {
	position: relative;
	overflow: hidden;
	z-index: 2;

	@include v.mq-down() {
		margin-bottom: v.pxtovw(70, 750);
		border-radius: v.pxtovw(60, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(75, 1440, true);
		border-radius: v.pxtovw(80, 1440, true);
	}
}

.section-03__picture {
	display: block;

	@include v.mq-down() {
		width: 100%;
		height: v.pxtovw(400, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(1160, 1440, true);
		height: v.pxtovw(450, 1440, true);
	}
}

.section-03__image {
	display: block;
	position: absolute;
	width: 100%;
	object-fit: cover;

	@include v.mq-down() {
		top: 5%;
		height: v.pxtovw(480, 750);
	}

	@include v.mq-up() {
		top: 0;
		height: v.pxtovw(550, 1440, true);
	}
}

.section-03__title {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	color: var(--color_white_01);

	@include v.mq-up() {
		padding-top: v.pxtovw(15, 1440, true);
	}
}

.section-03__content {
	position: relative;

	@include v.mq-down() {
		padding: 0 v.pxtovw(10, 750) 0 v.pxtovw(20, 750);
	}

	@include v.mq-up() {
		display: flex;
		align-items: center;
	}

	&--align-start {
		align-items: flex-start;
	}
}

.section-03__lead {
	position: relative;
	line-height: 2.4;
	letter-spacing: 0.6em;

	@include v.mq-down() {
		margin-bottom: v.pxtovw(40, 750);
		@include v.fz_vw(30);
	}

	@include v.mq-up() {
		display: flex;
		justify-content: center;
		width: 50%;
		padding-left: v.pxtovw(30, 1440, true);
		padding-bottom: 0.4em;
		box-sizing: border-box;
		font-size: 20px;
	}

	.section-03__content--align-start & {
		@include v.mq-up() {
			padding-top: v.pxtovw(53, 1440, true);
		}
	}
}

.section-03__body {
	line-height: 2.8;

	@include v.mq-up() {
		width: 50%;
		padding-left: v.pxtovw(60, 1440, true);
		box-sizing: border-box;
	}
}

//* section-04
//---------------------------------------------------------- */
.section-04 {

}

.section-04__title {
	@include v.mq-down() {
		margin-bottom: v.pxtovw(70, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(74, 1440, true);
	}
}

.section-04__outer {
	position: relative;

	@include v.mq-up() {
		padding: v.pxtovw(140, 1440, true) 0 v.pxtovw(160, 1440, true);
	}
}

.section-04__inner {
	position: relative;

	@include v.mq-down() {
		padding: v.pxtovw(80, 750) v.pxtovw(30, 750) v.pxtovw(127, 750);
	}

	@include v.mq-up() {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		width: v.pxtovw(1320, 1440, true);
		margin: 0 auto;
	}
}

.section-04__figure {
	@include v.mq-down() {
		display: none;
	}

	@include v.mq-up() {
		position: sticky;
		top: 180px;
		width: v.pxtovw(600, 1440, true);
		height: v.pxtovw(570, 1440, true);
	}
}

.section-04__image {
	@include v.mq-up() {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 0 v.pxtovw(100, 1440, true) v.pxtovw(100, 1440, true) 0;
		opacity: 0;
		transition: opacity 0.5s ease;
	}

	&.is-active {
		opacity: 1;
	}
}

.section-04__content {
	@include v.mq-up() {
		width: v.pxtovw(600, 1440, true);
		padding-top: v.pxtovw(68, 1440, true);
		padding-right: v.pxtovw(80, 1440, true);
		box-sizing: border-box;
	}
}

.section-04-article {
	counter-increment: title-03-counter;

	&:not(:last-child) {
		@include v.mq-down() {
			padding-bottom: v.pxtovw(100, 750);
		}

		@include v.mq-up() {
			padding-bottom: v.pxtovw(105, 1440, true);
		}
	}
}

.section-04-article__figure {
	@include v.mq-down() {
		margin-bottom: v.pxtovw(90, 750);
	}

	@include v.mq-up() {
		display: none;
	}
}

.section-04-article__image {
	@include v.mq-down() {
		width: 100%;
		height: v.pxtovw(570, 750);
		object-fit: cover;
		border-radius: v.pxtovw(100, 750);
	}
}

.section-04-article__title {
	@include v.mq-down() {
		margin-bottom: v.pxtovw(28, 750);
		padding: 0 v.pxtovw(10, 750) 0 v.pxtovw(20, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(20, 1440, true);
	}
}

.section-04-article__content {
	@include v.mq-down() {
		padding: 0 v.pxtovw(10, 750) 0 v.pxtovw(20, 750);
		line-height: 2.6;
	}

	@include v.mq-up() {
		padding-bottom: v.pxtovw(70, 1440, true);
		line-height: 2.8;
	}
}

//* section-05
//---------------------------------------------------------- */
.section-05 {
	position: relative;
	counter-increment: title-03-counter;

	&:not(:last-child) {
		@include v.mq-down() {
			padding-bottom: v.pxtovw(130, 750);
		}

		@include v.mq-up() {
			padding-bottom: v.pxtovw(140, 1440);
		}
	}
}

.section-05__inner {
	position: relative;
	z-index: 2;

	@include v.mq-up() {
		display: flex;
		align-items: center;
	}

	.section-05:nth-of-type(odd) & {
		@include v.mq-up() {
			gap: v.pxtovw(120, 1440);
		}
	}

	.section-05:nth-of-type(even) & {
		@include v.mq-up() {
			flex-flow: row-reverse;
			gap: v.pxtovw(52, 1440);
		}
	}
}

.section-05__figure {
	box-sizing: border-box;

	.section-05:nth-of-type(odd) & {
		@include v.mq-down() {
			margin-bottom: v.pxtovw(70, 750);
			width: v.pxtovw(660, 750);
		}

		@include v.mq-up() {
			width: v.pxtovw(662, 1440);
			padding-left: v.pxtovw(60, 1440);
		}
	}

	.section-05:nth-of-type(even) & {
		@include v.mq-down() {
			width: v.pxtovw(660, 750);
			margin-bottom: v.pxtovw(70, 750);
			margin-left: auto;
		}

		@include v.mq-up() {
			width: v.pxtovw(720, 1440);
		}
	}
}

.section-05__picture {
	display: block;
	overflow: hidden;

	.section-05:nth-of-type(odd) & {
		@include v.mq-down() {
			width: 100%;
			height: v.pxtovw(570, 750);
			border-radius: 0 v.pxtovw(100, 750) v.pxtovw(100, 750) 0;
		}

		@include v.mq-up() {
			width: v.pxtovw(600, 1440, true);
			height: v.pxtovw(570, 1440, true);
			border-radius: 0 v.pxtovw(100, 1440) v.pxtovw(100, 1440) 0;
		}
	}

	.section-05:nth-of-type(even) & {
		@include v.mq-down() {
			width: 100%;
			height: v.pxtovw(570, 750);
			border-radius: v.pxtovw(100, 750) 0 0 v.pxtovw(100, 750);
		}

		@include v.mq-up() {
			width: v.pxtovw(720, 1440, true);
			height: v.pxtovw(570, 1440, true);
			border-radius: v.pxtovw(100, 1440) 0 0 v.pxtovw(100, 1440);
		}
	}
}

.section-05__image {
	position: absolute;
	top: 2%;

	@include v.mq-up() {
		width: 100%;
		max-width: none;
	}

	.section-05:nth-of-type(even) & {
		@include v.mq-down() {
			height: v.pxtovw(700, 750);
		}
	}
}

.section-05__content {
	@include v.mq-down() {
		padding: 0 v.pxtovw(40, 750) 0 v.pxtovw(50, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(525, 1440);
	}
}

.section-05__title {
	@include v.mq-down() {
		margin-bottom: 1em;
	}

	@include v.mq-up() {
		margin-bottom: 1.3em;
	}
}

.section-05__body {
	letter-spacing: 0.4em;
	font-weight: 700;

	@include v.mq-down() {
		line-height: 2.6;
	}

	@include v.mq-up() {
		line-height: 2.8;
	}
}

.section-05-bottom {
	@include v.mq-down() {
		margin-top: v.pxtovw(120, 750);
	}

	@include v.mq-up() {
		margin-top: v.pxtovw(140, 1440);
	}
}

.section-05-bottom__picture {
	display: block;
	overflow: hidden;

	@include v.mq-down() {
		position: relative;
		width: 100%;
		height: v.pxtovw(570, 750);
	}

	@include v.mq-up() {
		width: 100%;
		height: v.pxtovw(500, 1440);
	}
}

.section-05-bottom__image {
	@include v.mq-down() {
		position: absolute;
		top: 5%;
		object-fit: cover;
		object-position: center top;
		width: auto;
		height: 110%;
	}

	@include v.mq-up() {
		width: 100%;
		max-width: none;
	}
}

//* section-06
//---------------------------------------------------------- */
.section-06 {
	counter-increment: title-03-counter;

	@include v.mq-down() {
		padding: 0 v.pxtovw(30, 750);
	}

	@include v.mq-up() {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: v.pxtovw(1160, 1440, true);
		margin: 0 auto;
	}

	&:not(:last-child) {
		@include v.mq-down() {
			margin-bottom: v.pxtovw(95, 750);
		}

		@include v.mq-up() {
			margin-bottom: v.pxtovw(60, 1440, true);
		}
	}
}

.section-06__figure {
	@include v.mq-down() {
		margin-bottom: v.pxtovw(90, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(520, 1440, true);
	}
}

.section-06__image {
	@include v.mq-down() {
		width: 100%;
		height: v.pxtovw(400, 750);
		object-fit: cover;
		border-radius: v.pxtovw(60, 750);
	}

	@include v.mq-up() {
		border-radius: v.pxtovw(40, 1440, true);
	}
}

.section-06__content {
	@include v.mq-down() {
		padding: 0 v.pxtovw(20, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(520, 1440, true);
	}
}

.section-06__title {
	@include v.mq-down() {
		margin-bottom: 1em;
	}

	@include v.mq-up() {
		margin-bottom: 1.5em;
	}
}

.section-06__body {
	@include v.mq-down() {
		line-height: 2.6;
	}

	@include v.mq-up() {
		line-height: 2.8;
	}
}

//* bg-01
//---------------------------------------------------------- */
.bg-01 {
	position: absolute;
	background: url('../images/common/bg_01_pc.png') no-repeat center;
	background-size: contain;

	@include v.mq-down() {
		width: v.pxtovw(1090, 750);
		height: v.pxtovw(1158, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(977, 1440, true);
		height: v.pxtovw(1183, 1440, true);

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
			background-image: url('../images/common/bg_01_pc@2x.png');
		}
	}
}

//* bg-circle
//---------------------------------------------------------- */
.bg-circle {
	position: absolute;

	&--01 {
		@include v.mq-up() {
			top: v.pxtovw(72, 1440, true);
			left: v.pxtovw(60, 1440, true);
			width: v.pxtovw(240, 1440, true);
			height: v.pxtovw(240, 1440, true);
			background: url('../images/common/bg_circle_01.png') no-repeat;
			background-size: contain;

			@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
				background-image: url('../images/common/bg_circle_01@2x.png');
			}
		}
	}

	&--02 {
		@include v.mq-up() {
			bottom: v.pxtovw(-20, 1440, true);
			right: v.pxtovw(-60, 1440, true);
			width: v.pxtovw(280, 1440, true);
			height: v.pxtovw(280, 1440, true);
			background: url('../images/common/bg_circle_02.png') no-repeat;
			background-size: contain;

			@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
				background-image: url('../images/common/bg_circle_02@2x.png');
			}
		}
	}

	&--03 {
		@include v.mq-up() {
			bottom: v.pxtovw(48, 1440, true);
			left: v.pxtovw(-40, 1440, true);
			width: v.pxtovw(280, 1440, true);
			height: v.pxtovw(280, 1440, true);
			background: url('../images/common/bg_circle_03.png') no-repeat;
			background-size: contain;

			@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
				background-image: url('../images/common/bg_circle_03@2x.png');
			}
		}
	}

	&--04 {
		@include v.mq-up() {
			top: v.pxtovw(38, 1440, true);
			right: v.pxtovw(-15, 1440, true);
			width: v.pxtovw(240, 1440, true);
			height: v.pxtovw(240, 1440, true);
			background: url('../images/common/bg_circle_04.png') no-repeat;
			background-size: contain;

			@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
				background-image: url('../images/common/bg_circle_04@2x.png');
			}
		}
	}

	&--05 {
		@include v.mq-up() {
			bottom: v.pxtovw(115, 1440, true);
			right: v.pxtovw(-80, 1440, true);
			width: v.pxtovw(300, 1440, true);
			height: v.pxtovw(300, 1440, true);
			background: url('../images/common/bg_circle_05.png') no-repeat;
			background-size: contain;

			@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
				background-image: url('../images/common/bg_circle_05@2x.png');
			}
		}
	}

	&--06 {
		background: url('../images/common/bg_circle_06.png') no-repeat;
		background-size: contain;

		@include v.mq-up() {
			top: v.pxtovw(180, 1440, true);
			right: v.pxtovw(-70, 1440, true);
			width: v.pxtovw(280, 1440, true);
			height: v.pxtovw(280, 1440, true);

			@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
				background-image: url('../images/common/bg_circle_06@2x.png');
			}
		}
	}
}

//* bg-text
//---------------------------------------------------------- */
.bg-text {
	display: flex;
	position: relative;
	flex-flow: row nowrap;
	width: 100vw;
	overflow: hidden;
	pointer-events: none;
}

.bg-text__list {
	display: flex;
	flex-flow: row nowrap;

	&:first-child {
		animation: slider1 120s -60s linear infinite;
	}

	&:last-child {
		animation: slider2 120s linear infinite;
	}
}

.bg-text__item {
	margin-right: 0.5em;
	letter-spacing: 0;
	font-family: var(--font_sans_en);
	font-weight: 700;
	white-space: nowrap;

	@include v.mq-down() {
		@include v.fz_vw(140);
	}

	@include v.mq-up() {
		@include v.fz_vw(180, 1440);
	}
}

.bg-text__image {
	width: 100%;
	max-width: none;
}

@keyframes slider1 {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slider2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}

//* splide
//---------------------------------------------------------- */
.splide__pagination {
	&--ttb {
		flex-direction: column;
	}
}

.slider-pagination {

}

.slider-pagination__list {
	display: flex;

	@include v.mq-down() {
		gap: v.pxtovw(14, 750);
	}

	@include v.mq-up() {
		flex-flow: column;
		gap: 3px;
	}
}

.slider-pagination__svg {
	// enable-background: new 0 0 27 27;
	transform: rotate(-90deg);

	@include v.mq-down() {
		width: v.pxtovw(50, 750);
		height: v.pxtovw(50, 750);
	}

	@include v.mq-up() {
		width: 26px;
		height: 26px;
	}

	.st0{
		fill-rule: evenodd;
		clip-rule: evenodd;
		fill: #f9f9f9;
		transition: fill 0.2vs ease;

		.slider-pagination__item.is-active & {
			fill: var(--color_red_01);
		}
	}
	.st1{
		fill: none;
		stroke: #fafafa;
		// stroke: var(--color_red_01);
		stroke-dashoffset: 100;
		stroke-dasharray: 100;

		.slider-pagination__item.is-active & {
			animation: pagenation 4s linear infinite;
		}

	}
}

@keyframes pagenation {
  0% {
    stroke-dashoffset: 200;
  }
  90% {
    stroke-dashoffset: 100;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.splide__arrows {
	pointer-events: none;
}

.splide__arrow {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	border-radius: 50%;
	box-sizing: border-box;
	background-color: var(--color_white_01);
	pointer-events: auto;

	@include v.mq-down() {
		width: v.pxtovw(90, 750);
		height: v.pxtovw(90, 750);
 	}

	@include v.mq-up() {
		width: v.pxtovw(70, 1440, true);
		height: v.pxtovw(70, 1440, true);
		transition: background-color 0.4s ease;
	}

	&:not([disabled]):hover {
		@include v.mq-up() {
			background-color: var(--color_red_02);
		}

		path {
			@include v.mq-up() {
				fill: var(--color_white_01);
			}
		}
	}

	svg {
		@include v.mq-down() {
			display: block;
			width: v.pxtovw(30, 750);
			transform: scale(2);
		}

		@include v.mq-up() {
			width: v.pxtovw(40, 1440, true);
		}
	}

	path {
		fill: var(--color_red_02);

		@include v.mq-up() {
			transition: fill 0.4s ease;
		}
	}
}

.splide__arrow {

	&[disabled] {
		opacity: 0.8;
	}

	&--prev svg {
		@include v.mq-down() {
			transform: scale(-2, 2);
		}

		@include v.mq-up() {
			transform: scaleX(-1);
		}
	}
}