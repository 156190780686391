@use '../mixin' as v;

/* UTILITY
---------------------------------------------------------- */
.is-pc {
  @include v.mq-down() {
    display: none;
  }
}

.is-sp {
  @include v.mq-up() {
    display: none;
  }
}

.is-disable-link {
  pointer-events: none;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right{
  text-align: right;
}

@for $i from 0 through 10 {
  .mt--#{$i * 5} {
    margin-top: #{$i * 5}px !important;
  }
  .mb--#{$i * 5} {
    margin-bottom: #{$i * 5}px !important;
  }
  .mr--#{$i * 5} {
    margin-right: #{$i * 5}px !important;
  }
  .ml--#{$i * 5} {
    margin-left: #{$i * 5}px !important;
  }
  .pt--#{$i * 5} {
    padding-top: #{$i * 5}px !important;
  }
  .pb--#{$i * 5} {
    padding-bottom: #{$i * 5}px !important;
  }
  .pr--#{$i * 5} {
    padding-right: #{$i * 5}px !important;
  }
  .pl--#{$i * 5} {
    padding-left: #{$i * 5}px !important;
  }
}

.display-none {
  display: none;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.blue {
  color: var(--color_blue_01);
}

.large-text {
  letter-spacing: 0.2em;

  @include v.mq-down() {
    @include v.fz_vw(32);
  }

  @include v.mq-up() {
    font-size: 24px;
  }
}

.small-text {
  letter-spacing: 0.1em;

  @include v.mq-down() {
    @include v.fz_vw(22);
  }

  @include v.mq-up() {
    font-size: 12px;
  }
}

.dash {
  display: inline-block;
  align-items: center;
  width: 2.5em;
  height: 1px;
  margin-left: 0.2em;
  background-color: var(--color_black_01);
  vertical-align: middle;
}

// .line-marker {
//   text-decoration: underline;
//   text-decoration-thickness: 0.28em;
//   text-decoration-color: #fdf22c;
//   text-underline-offset: -0.14em;
//   text-decoration-skip-ink: none;
// }