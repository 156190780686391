@use '../mixin' as v;

.contact {
	position: relative;
	overflow: hidden;

	@include v.mq-up() {
		text-align: center;
		padding-top: v.pxtovw(180, 1440, true);
	}
}


.contact__bg {
	bottom: 0;
	right: v.pxtovw(-100, 1440, true);
}

.contact__inner {
	position: relative;

	@include v.mq-down() {
		padding: v.pxtovw(120, 750) v.pxtovw(50, 750) 0;
	}
}

.contact__body {
	letter-spacing: 0.4em;
	line-height: 2.4;

	@include v.mq-down() {
		margin-bottom: 3em;
		@include v.fz_vw(26);
	}

	@include v.mq-up() {
		margin-bottom: 6.2em;
		font-size: 16px;
	}
}

.contact-tel__title {
	position: relative;
	background-color: var(--color_white_01);
	border-radius: 2em;

	@include v.mq-down() {
		display: block;
		margin: 0 auto 1.9em;
		padding: 1.067em 3.21em;
		text-align: center;
		@include v.fz_vw(28);
	}

	@include v.mq-up() {
		display: inline-block;
		margin-bottom: 1.9em;
		padding: 1.067em 4.21em;
		letter-spacing: 0.4em;
		font-size: 18px;
	}

	&::after {
		display: block;
		position: absolute;
		left: calc(50% - 1em);
		bottom: -1.06em;
		width: 1.3em;
		height: 1.06em;
		background-color: inherit;
		clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
		content: '';
	}
}

.contact-tel__number {
	margin-bottom: 0.35em;
	letter-spacing: 0.3em;
	font-family: var(--font_sans_en);
	color: var(--color_red_02);

	@include v.mq-down() {
		text-align: center;
		@include v.fz_vw(54);
	}

	@include v.mq-up() {
		font-size: 50px;
	}
}

.contact-address {
	@include v.mq-up() {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 2.5em;
	}

	@include v.mq-down() {
		text-align: center;
	}
}

.contact-address__body {
	letter-spacing: 0.3em;

	@include v.mq-down() {
		margin-bottom: 1em;
		@include v.fz_vw(28);
	}

	@include v.mq-up() {
		font-size: 18px;
	}
}