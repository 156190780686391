@use '../mixin' as v;

.philosophy {
	position: relative;

	@include v.mq-up() {
	}
}

.philosophy__figure {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.philosophy__picture {
	display: block;
	position: relative;
	overflow: hidden;

	@include v.mq-down() {
		height: 100%;
	}

	@include v.mq-up() {
		width: 100%;
		height: v.pxtovw(660, 1440, true);
	}
}

.philosophy__image {
	position: absolute;
	top: 5%;

	@include v.mq-up() {
		width: 100%;
		max-width: none;
		height: 100%;
		object-fit: cover;
	}

	@include v.mq-up(xxl) {
		max-height: 660px;
	}
}

.philosophy__inner {
	position: relative;
	// position: absolute;
	// top: 0;
	// left: 0;
	// width: 100%;
	// height: 100%;

	@include v.mq-down() {
		padding: v.pxtovw(120, 750) v.pxtovw(40, 750) v.pxtovw(140, 750 ) v.pxtovw(50, 750);
	}

	@include v.mq-up() {
		padding: v.pxtovw(132, 1440, true) 0 v.pxtovw(140, 1440, true);
		box-sizing: border-box;
	}
}

.philosophy__title {
	color: var(--color_white_01);

	@include v.mq-down() {
		margin-bottom: v.pxtovw(70, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(82, 1440, true);
	}
}

.philosophy__content {
	color: var(--color_white_01);

	@include v.mq-up() {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: v.pxtovw(80, 1440, true);
		width: v.pxtovw(1160, 1440, true);
		margin: 0 auto;
	}
}

.philosophy__lead {
	line-height: 2.4;
	letter-spacing: 0.6em;
	font-weight: 700;

	@include v.mq-down() {
		margin-bottom: v.pxtovw(40, 750);
		@include v.fz_vw(30);
	}

	@include v.mq-up() {
		width: 19.5em;
		margin-bottom: 0.7em;
		padding-left: v.pxtovw(130, 1440, true);
		font-size: 20px;
	}
}

.philosophy__body {
	line-height: 2.8;
	letter-spacing: 0.4em;

	@include v.mq-up() {
		width: v.pxtovw(520, 1440, true);
	}
}

//* information
//---------------------------------------------------------- */
.information {
	@include v.mq-down() {
		padding-top: v.pxtovw(120, 750);
		overflow: hidden;
	}

	@include v.mq-up() {
		padding-top: v.pxtovw(172, 1440, true);
	}
}

.information__bg {
	@include v.mq-down() {
		width: 100%;
		background-size: cover;
	}
}

.information__title {
	@include v.mq-down() {
		margin-bottom: v.pxtovw(70, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(75, 1440, true);
	}
}

.information__list {
	position: relative;

	dd:last-child {
		@include v.mq-up() {
			margin-bottom: 10em;
		}
	}
}