@use '../mixin' as v;

//* advantage
//---------------------------------------------------------- */
.advantage {
	@include v.mq-up() {

	}
}

.advantage-bg {
	@include v.mq-up() {
		top: 0;
		height: 75%;
	}
}

.advantage-bg__clip::before {

	@include v.mq-down() {
		background-image: url('../images/speciality/advantage_bg_sp.jpg');
		background-size: 100vh;
	}

	@include v.mq-up() {
		background-image: url('../images/speciality/advantage_bg_pc.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
			background-image: url('../images/speciality/advantage_bg_pc@2x.png');
		}
	}
}

//* certificates
//---------------------------------------------------------- */
.certificates {
	@include v.mq-down() {
		padding-bottom: v.pxtovw(145, 750);
	}

	@include v.mq-up() {
		padding-bottom: v.pxtovw(173, 1440, true);
	}
}

.certificates__bg {
	@include v.mq-down() {
		display: none;
	}
}

.certificate__inner {
	display: flex;

	@include v.mq-down() {
		flex-flow: column;
		gap: v.pxtovw(80, 750);
		padding: 0 v.pxtovw(50, 750);
	}

	@include v.mq-up() {
		flex-wrap: wrap;
		gap: v.pxtovw(34, 1440, true);
		width: v.pxtovw(1160, 1440, true);
		margin: 0 auto;
	}
}

.certificates__title {
	@include v.mq-down() {
		margin-bottom: v.pxtovw(70, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(75, 1440, true);
	}
}

.certificate {
	position: relative;

	@include v.mq-up() {
		width: v.pxtovw(364, 1440, true);
		min-height: v.pxtovw(400, 1440, true);
		padding: v.pxtovw(30, 1440, true);
		border-radius: v.pxtovw(10, 1440, true);
		background-color: var(--color_white_01);
		box-sizing: border-box;
	}
}

.certificate__figure {
	margin-bottom: 0.9em;
}

.certificate__image {
	@include v.mq-down() {
		width: 100%;
		max-width: none;
	}
}

.certificate__year {
	display: inline-block;
	padding: 0.25em 0.4em 0.25em 0.52em;
	border-radius: 2em;
	background-color: var(--color_red_02);
	line-height: 1;
	letter-spacing: 0.4em;
	color: var(--color_white_02);

	@include v.mq-down() {
		@include v.fz_vw(24);
	}

	@include v.mq-up() {
		font-size: 12px;
	}
}

.certificate__title {
	margin-bottom: 0.6em;
	padding-bottom: 0.5em;
	border-bottom: 1px solid;
	line-height: 2;
	letter-spacing: 0.4em;
	font-weight: 700;

	@include v.mq-up() {
		font-size: 14px;
	}
}

.certificate__content {
	line-height: 2;
	letter-spacing: 0.4em;
	font-weight: 700;

	@include v.mq-down() {
		@include v.fz_vw(20);
	}

	@include v.mq-up() {
		font-size: 10px;
	}
}

//* works
//---------------------------------------------------------- */
.works {}

.works__title {
	@include v.mq-down() {
		margin-bottom: v.pxtovw(70, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(30, 1440, true);
	}
}

.works__desc {
	line-height: 2.8;

	@include v.mq-down() {
		margin-bottom: v.pxtovw(70, 750);
		padding: 0 v.pxtovw(30, 750) 0 v.pxtovw(50, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(70, 1440, true);
		text-align: center;
	}
}

.works-pager {
	display: flex;
	gap: 0.2em;
	justify-content: flex-end;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;
	font-family: var(--font_sans_en);
	font-weight: 700;
	color: var(--color_red_02);

	@include v.mq-down() {
		width: v.pxtovw(690, 750);
		top: v.pxtovw(85, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(1020, 1440, true);
		top: v.pxtovw(155, 1440, true);
	}
}

.works-pager__button {
	border: none;
	background-color: transparent;
	color: var(--color_red_02);

	@include v.mq-down() {
		@include v.fz_vw(24);
	}

	@include v.mq-up() {
		font-size: 14px;
	}
}

.works-pager__number {
	display: flex;
	align-items: center;

	@include v.mq-down() {
		letter-spacing: 0.3em;
		@include v.fz_vw(20);
	}

	@include v.mq-up() {
		font-size: 12px;
	}
}

.works-pager__current {
	display: flex;
	align-items: center;

	&::after {
		display: block;
		margin-right: 0.6em;
		background-color: var(--color_red_02);
		transform: rotate(120deg);
		content: '';

		@include v.mq-down() {
			width: v.pxtovw(20, 750);
			height: v.pxtovw(4, 750);
		}

		@include v.mq-up() {
			width: 12px;
			height: 2px;
		}
	}
}

.works-slide {
	counter-increment: title-03-counter;
	position: relative;

	@include v.mq-down() {
		height: v.pxtovw(1424, 750);
	}

	@include v.mq-up() {
		height: v.pxtovw(780, 1440, true);
	}
}

.works-slide__bg {
	@include v.mq-down() {
		height: 100%;
	}
}

.works-slide__bg-image {
	width: 100%;
	max-width: none;
	height: 100%;
	object-fit: cover;
}

.works-slide__inner {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;

	@include v.mq-down() {
		align-items: flex-start;
		padding-top: v.pxtovw(95, 750);
	}

	@include v.mq-up() {
		align-items: center;
		padding-bottom: v.pxtovw(25, 1440, true);
	}
}

.works-slide__content {
	margin: 0 auto;

	@include v.mq-down() {
		width: v.pxtovw(650, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(1040, 1440, true);
	}
}

.works-slide__title {
	@include v.mq-down() {
		margin-bottom: v.pxtovw(35, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(67, 1440, true);
		padding: 0 v.pxtovw(40, 140, true);
	}

	.title-03__en {
		@include v.mq-down() {
			margin-bottom: 1.3em;
		}
	}

	.title-03__ja {
		@include v.mq-down() {
			line-height: 2.4;
		}
	}
}

.works-slide__figures {
	display: flex;

	@include v.mq-down() {
		flex-flow: column;
		gap: v.pxtovw(32, 750);
		width: v.pxtovw(610, 750);
		margin: 0 auto;
	}

	@include v.mq-up() {
		justify-content: center;
		gap: v.pxtovw(20, 1440, true);
	}
}

.works-slide__figure {
	position: relative;

	@include v.mq-down() {
		width: 100%;
		height: v.pxtovw(410, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(510, 1440, true);
	}
}

.works-slide__image {
	@include v.mq-down() {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: v.pxtovw(40, 750);
	}

	@include v.mq-up() {
		border-radius: v.pxtovw(40, 1440, true);
	}
}

.works-slide__caption {
	position: absolute;
	padding: 0.25em 0.35em 0.25em 0.65em;
	border-radius: 2em;
	letter-spacing: 0.4em;
	line-height: 1;
	font-weight: 700;

	@include v.mq-down() {
		top: 1.3em;
		left: 1.3em;
		@include v.fz_vw(24);
	}

	@include v.mq-up() {
		top: 1.7em;
		left: 3.3em;
		font-size: 12px;
	}

	&--before {
		background-color: var(--color_white_01);
		color: var(--color_red_02);
	}

	&--after {
		background-color: var(--color_red_02);
		color: var(--color_white_01);
	}
}

.works__track {}

.works__arrows {
	display: flex;
	justify-content: space-between;
	position: absolute;
	z-index: 20;

	@include v.mq-down() {
		gap: v.pxtovw(18, 750);
		right: v.pxtovw(45, 750);
		bottom: v.pxtovw(130, 750);
	}

	@include v.mq-up() {
		top: 53%;
		left: 50%;
		transform: translateX(-50%);
		width: v.pxtovw(1107, 1440, true);
	}
}