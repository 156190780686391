@use '../mixin' as v;

//* footer-carousel
//---------------------------------------------------------- */
.footer-carousel {
	position: relative;
	overflow: hidden;

	@include v.mq-down(lgm) {
		padding-bottom: v.pxtovw(120, 750);
	}
}

.footer-carousel__bg {
	position: absolute;

	@include v.mq-down(lgm) {
		display: none;
	}

	@include v.mq-up(lgm) {
		bottom: v.pxtovw(100, 1440, true);
		right: v.pxtovw(-100, 1440, true);
	}
}

.footer-carousel__inner {
	display: flex;
	overflow: hidden;

	@include v.mq-down(lgm) {
		padding-top: v.pxtovw(260, 750);
	}

	@include v.mq-up(lgm) {
		padding: v.pxtovw(240, 1440, true) 0 v.pxtovw(170, 1440, true);
	}
}

.footer-carousel__list {
	display: flex;
	flex-flow: nowrap;

	@include v.mq-down(lgm) {
		gap: v.pxtovw(30, 750);
		padding-right: v.pxtovw(30, 750);
	}

	@include v.mq-up(lgm) {
		gap: v.pxtovw(30, 1440, true);
		padding-right: v.pxtovw(30, 1440, true);
	}

	&:nth-child(1) {
		animation: carousel-1 100s -50s linear infinite;
	}

	&:nth-child(2) {
		animation: carousel-2 100s linear infinite;
	}
}

.footer-carousel__item {
	@include v.mq-down(lgm) {
		width: v.pxtovw(470, 750);
	}

	@include v.mq-up(lgm) {
		width: v.pxtovw(470, 1440);
	}
}

.section-people__image {
	width: 100%;
	max-width: none;

	@include v.mq-down(lgm) {
		border-radius: v.pxtovw(50, 750);
	}

	@include v.mq-up(lgm) {
		width: 100%;
		max-width: none;
		border-radius: v.pxtovw(50, 1440, true);
	}
}

@keyframes carousel-1 {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes carousel-2 {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-200%);
  }
}

//* footer-contact
//---------------------------------------------------------- */
.footer-contact {
	@include v.mq-down(lgm) {
		padding-bottom: v.pxtovw(340, 750);
	}

	@include v.mq-up(lgm) {
		padding-bottom: v.pxtovw(270, 1440, true);
	}
}

.footer-contact__inner {
	text-align: center;
}

.footer-contact__text {
	letter-spacing: 0.4em;
	font-weight: 700;

	@include v.mq-down(lgm) {
		margin-bottom: v.pxtovw(60, 750);
		line-height: 2.6;
		@include v.fz_vw(26);
	}

	@include v.mq-up(lgm) {
		margin-bottom: v.pxtovw(52, 1440, true);
		font-size: 16px;
	}
}

//* footer
//---------------------------------------------------------- */
.footer {
	position: relative;

	@include v.mq-down(lgm) {
		padding: v.pxtovw(366, 750) 0 v.pxtovw(120, 750);
	}

	@include v.mq-up(lgm) {
		padding: v.pxtovw(280, 1440, true) 0 v.pxtovw(50, 1440, true);
		// background: url('../images/common/footer_bg_pc.jpg') no-repeat;
		// background-size: cover;

		// @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
		// 	background-image: url('../images/common/footer_bg_pc@2x.jpg');
		// }
	}
}

.footer-bg {
	top: 0;
	height: 100%;
}

.footer-bg__clip {
	height: 100%;

	&::before {
		height: 100%;
		background-position: center top;
		background-size: cover;

		@include v.mq-down(lgm) {
			background-image: url('../images/common/footer_bg_sp.jpg?20230713');
		}

		@include v.mq-up(lgm) {
			background-image: url('../images/common/footer_bg_pc.jpg?20230713');
			background-size: cover;
			// background-attachment: fixed;

			@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
				background-image: url('../images/common/footer_bg_pc@2x.jpg?20230713');
			}
		}
	}

	&::after {
		display: none;
	}
}

.footer-peanuts {
	position: absolute;
	opacity: 0;
	transform: translateY(30px);
	transition: transform 0.6s ease-out, opacity 0.6s ease-out;

	@include v.mq-down(lgm) {
		top: v.pxtovw(-140, 750);
		left: calc( 50% - v.pxtovw(173.5, 750) );
		width: v.pxtovw(347, 750);
	}

	@include v.mq-up(lgm) {
		top: v.pxtovw(-90, 1440, true);
		left: calc( 50% - v.pxtovw(115.5, 1440, true) );
		width: v.pxtovw(231, 1440, true);
	}

	&.is-inview {
		transform: translateY(0);
		opacity: 1;
	}
}

.footer-peanuts__image {
	width: 100%;
	max-width: none;

	@include v.mq-up(lgm) {
		margin-bottom: v.pxtovw(50, 1440, true);
	}
}

.footer-peanuts__caption {
	position: relative;
	padding: 0.7em 0 0.8em;
	background-color: #fff;
	text-align: center;
	line-height: 1.7;
	letter-spacing: 0.15em;
	font-weight: 700;

	@include v.mq-up(lgm) {
		border-radius: v.pxtovw(30, 1440, true);
		font-size: 12px;
	}

	&::before {
		display: block;
		position: absolute;
		background-color: inherit;
		clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
		content: '';

		@include v.mq-up(lgm) {
			top: -20px;
			left: calc( 50% - 10px );
			width: 16px;
			height: 20px;
		}
	}
}

.footer__inner {
	position: relative;

	@include v.mq-up(lgm) {

	}
}

.footer-logo {
	text-align: center;

	@include v.mq-down(lgm) {
		margin-bottom: 1em;
	}

	@include v.mq-up(lgm) {
		margin-bottom: 1.5em;
	}
}

.footer-logo__image {
	@include v.mq-down(lgm) {
		width: v.pxtovw(480, 750);
	}
}

.footer__address {
	text-align: center;
	line-height: 1.8;
	letter-spacing: 0.15em;
	font-weight: 700;

	@include v.mq-down(lgm) {
		margin-bottom: v.pxtovw(100, 750);
		@include v.fz_vw(24);
	}

	@include v.mq-up(lgm) {
		margin-bottom: v.pxtovw(55, 1440, true);
		font-size: 16px;
	}
}

//* footer-nav
//---------------------------------------------------------- */
.footer-nav {
	@include v.mq-down(lgm) {
		margin-bottom: v.pxtovw(130, 750);
	}

	@include v.mq-up(lgm) {
		margin-bottom: v.pxtovw(65, 1440, true);
	}
}

.footer-nav__list {
	display: flex;

	@include v.mq-down(lgm) {
		flex-wrap: wrap;
		gap: 1.6em 0;
		width: v.pxtovw(620, 750);
		margin: 0 auto;
	}

	@include v.mq-up(lgm) {
		justify-content: center;
		gap: v.pxtovw(18, 1440, true);
	}
}

.footer-nav__item {
	@include v.mq-down(lgm) {
		width: 50%;
	}
}

.footer-nav__link {
	@include v.mq-down(lgm) {
		@include v.fz_vw(24);
	}
}

//* footer__bottom
//---------------------------------------------------------- */
.footer__bottom {
	position: relative;

	@include v.mq-down(lgm) {
		padding: 0 v.pxtovw(50, 750);
	}

	@include v.mq-up(lgm) {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 2em;
		width: v.pxtovw(960, 1440, true);
		margin: 0 auto;
	}
}

.footer__button {
	@include v.mq-down(lgm) {
		margin-bottom: 0.5em;
	}
}

.footer__copyright {
	letter-spacing: 0.15em;
	color: var(--color_red_01);

	@include v.mq-down(lgm) {
		@include v.fz_vw(20);
	}

	@include v.mq-up(lgm) {
		font-size: 12px;
	}
}

.pagetop {
	position: fixed;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.4s ease;
	z-index: 10;

	@include v.mq-down(lgm) {
		bottom: v.pxtovw(50, 750);
		right: v.pxtovw(50, 750);
		width: v.pxtovw(120, 750);
		height: v.pxtovw(120, 750);
	}

	@include v.mq-up(lgm) {
		right: 60px;
		bottom: 40px;
	}

	.is-scrolled & {
		opacity: 1;
		pointer-events: auto;
	}

	&.is-hidden {
		opacity: 0;
		pointer-events: none;
	}
}