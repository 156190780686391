@use '../mixin' as v;

.intro-copy__image--anniversary {

		top: -25%;

}

//* message
//---------------------------------------------------------- */
.message {
	position: relative;

	@include v.mq-down() {
		padding-bottom: v.pxtovw(90, 750);
	}

	@include v.mq-up() {
		padding-top: v.pxtovw(132, 1440, true);
		padding-bottom: v.pxtovw(173, 1440, true);
	}
}

.message-bg {
	@include v.mq-up() {
		top: 0;
		height: v.pxtovw(1190, 1440, true);
	}
}

.message-bg__clip::before {

	@include v.mq-down() {
		background-image: url('../images/anniversary/message_bg_sp.jpg');
	}

	@include v.mq-up() {
		background-image: url('../images/anniversary/message_bg_pc.png');

		@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
			background-image: url('../images/anniversary/message_bg_pc@2x.png');
		}
	}
}


.message__inner {
	position: relative;
	border-bottom: 1px solid;

	@include v.mq-down() {
	}


	@include v.mq-down() {
		width: v.pxtovw(650, 750);
		margin: 0 auto v.pxtovw(90, 750);
		padding: v.pxtovw(80, 750) 0 v.pxtovw(60, 750);
	}

	@include v.mq-up() {
		width: calc(100% - v.pxtovw(120, 1440));
		margin: 0 auto v.pxtovw(100, 1440, true);
		padding-bottom: v.pxtovw(90, 1440, true);
	}
}

.message__title {
	@include v.mq-down() {
		margin-bottom: v.pxtovw(60, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(41, 1440, true);
	}
}

.message__content {
	position: relative;
}

//* message-written
//---------------------------------------------------------- */
.message-written {
	display: flex;
	flex-flow: column;
	align-items: center;
}

.message-written-01 {
	@include v.mq-down() {
		width: v.pxtovw(243.75, 750);
	}

	@include v.mq-up() {
		width: 195px;
	}
}

.message-written-01__text {
	fill-rule: evenodd;
	clip-rule: evenodd;
	fill: #453D36;
	mask: url(#text-mask-01);
}

.message-written-01__path {
	fill-rule: evenodd;
	clip-rule: evenodd;
	fill: none;
	stroke: #fff;
	stroke-width: 4;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-miterlimit: 10;
	// stroke-dasharray:1650;
}

.message-written-02 {
	@include v.mq-down() {
		width: v.pxtovw(466.25, 750);
	}

	@include v.mq-up() {
		width: 373px;
	}
}

.message-written-02__text{
	fill-rule: evenodd;
	clip-rule: evenodd;
	fill: #453D36;
	mask: url(#text-mask-02);
}

.message-written-02__path {
	fill-rule: evenodd;
	clip-rule: evenodd;
	fill: none;
	stroke: #FFF;
	stroke-width: 8;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-miterlimit: 10;
}

.text-mask-01 {
	stroke-dasharray: 2520;
	stroke-dashoffset: 2520;

	.is-inview & {
		animation: h-written 5s linear forwards;
	}
}

.text-mask-02 {
	stroke-dasharray: 2520;
	stroke-dashoffset: 2520;

	.is-inview & {
		animation: h-written 3.2s linear 1s forwards;
	}
}

@keyframes h-written {
	0% {
		stroke-dashoffset: 2520;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

//* history
//---------------------------------------------------------- */
.history {
	position: relative;
	overflow: hidden;
}

.history__bg {
	@include v.mq-up() {
		bottom: 0;
	}
}

.history__title {
	@include v.mq-down() {
		margin-bottom: v.pxtovw(70, 750);
	}

	@include v.mq-up() {
		margin-bottom: v.pxtovw(71, 1440, true);
	}
}

.history__list {
	position: relative;
	padding-top: 0.2em;
	padding-bottom: 1em;
	border-left: 1px dashed var(--color_black_01);
	box-sizing: border-box;

	@include v.mq-down() {
		width: v.pxtovw(650, 750);
		margin: 0 auto;
		padding: 0 0 0 v.pxtovw(65, 750);
	}

	@include v.mq-up() {
		width: v.pxtovw(1160, 1440, true);
		max-width: 853px;
		margin: 0 auto;
		padding-left: v.pxtovw(65, 1440, true);
	}
}

.history__date {
	position: relative;
	margin-bottom: 0.5em;
	letter-spacing: 0.2em;
	font-weight: 700;
	color: var(--color_red_02);

	@include v.mq-down() {
		@include v.fz_vw(20);
	}

	@include v.mq-up() {
		font-size: 10px;
	}

	&::before {
		display: block;
		position: absolute;
		border-radius: 50%;
		background-color: var(--color_red_02);
		content: '';

		@include v.mq-down() {
			top: calc(50% - v.pxtovw(8, 750));
			left: calc(v.pxtovw(-65, 750) - v.pxtovw(8, 750));
			width: v.pxtovw(16, 750);
			height: v.pxtovw(16, 750);
		}

		@include v.mq-up() {
			top: calc(50% - 4px);
			left: calc(v.pxtovw(-65, 1440, true) - 4px);
			width: 8px;
			height: 8px;
		}
	}
}

.history__content {
	line-height: 1.8;

	&:not(:last-child) {
		margin-bottom: 2em;
	}
}